import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AdminSplash from "./screens/admin/AdminSplash/AdminSplash";
import AdminLogin from "./screens/admin/AdminLogin/AdminLogin";
import AddEmployeeAdmin from "./screens/admin/AddEmployeeAdmin/AddEmployeeAdmin";
import AdminTasksList from "./screens/admin/AdminTasksList/AdminTasksList";
import AdminAddTask from "./screens/admin/AdminTasksList/AdminAddTask";
import TasksScheduler from "./screens/admin/TasksScheduler/TasksScheduler";
import EditEmployeeAdmin from "./screens/admin/AddEmployeeAdmin/EditEmployeeAdmin";
import EmployeeListing from "./screens/admin/EmployeeListing/EmployeeListing";
import SliderDemo from "./screens/admin/SliderDemo";
import ReportAdmin from "./screens/admin/ReportAdmin/ReportAdmin";
import AdminEditTask from "./screens/admin/AdminTasksList/AdminEditTask";
import WeeklyTasks from "./screens/admin/AdminTasksList/WeeklyTasks";
import TaskDetails from "./screens/admin/AdminTasksList/TaskDetails";
import SplashEmployee from "./screens/employee/SplashEmployee/SplashEmployee";
import LoginEmployee from "./screens/employee/LoginEmployee/LoginEmployee";
import EmployeeTaskList from "./screens/employee/EmployeeTaskList/EmployeeTaskList";
import AddCategory from "./screens/admin/AdminTasksList/AddCategory";
import AddFrequency from "./screens/admin/AdminTasksList/AddFrequency";
import Login from "./screens/admin/AdminLogin/Login";
import CategoryDetails from "./screens/admin/AdminTasksList/CategoryDetails";
import SuperAdminDashboard from "./screens/admin/SuperAdmin/superAdminDashboard/SuperAdminDashboard";
import AddCompany from "./screens/admin/SuperAdmin/AddCompany";
import AddCompanyAdmin from "./screens/admin/SuperAdmin/AddCompanyAdmin";
import AdminList from "./screens/admin/SuperAdmin/AdminList";
import EditCompanyAdmin from "./screens/admin/SuperAdmin/EditCompanyAdmin";

import CheckTasksAvailability from "./screens/employee/CheckTasksAvailability/CheckTasksAvailability";
import CreateNewSchedule from "./screens/admin/AdminTasksList/CreateNewSchedule";
import MyAvailability from "./screens/employee/MyAvailability/MyAvailability";

function App() {
  return (
    <Router>
      <Routes>
        {/* Admin Screens */}
        <Route path="/" element={<AdminSplash />} />
        <Route path="EmployeeLogin" element={<AdminLogin />} />
        <Route path="Login" element={<Login />} />
        <Route path="EmployeeListing" element={<EmployeeListing />} />
        <Route path="AddEmployeeAdmin" element={<AddEmployeeAdmin />} />
        <Route
          path="EditEmployeeAdmin/:empId"
          element={<EditEmployeeAdmin />}
        />
        <Route path="AdminTasksList" element={<AdminTasksList />} />
        <Route path="AdminAddTask" element={<AdminAddTask />} />
        <Route path="AdminEditTask" element={<AdminEditTask />} />
        <Route path="TasksScheduler" element={<TasksScheduler />} />
        <Route path="SliderDemo" element={<SliderDemo />} />
        <Route path="ReportAdmin" element={<ReportAdmin />} />
        <Route path="WeeklyTasks" element={<WeeklyTasks />} />
        <Route path="TaskDetails" element={<TaskDetails />} />
        <Route path="CategoryDetails" element={<CategoryDetails />} />
        <Route path="AddCategory" element={<AddCategory />} />
        <Route path="AddFrequency" element={<AddFrequency />} />
        <Route
          path="CheckTasksAvailability"
          element={<CheckTasksAvailability />}
        />
        <Route path="CreateNewSchedule" element={<CreateNewSchedule />} />

        {/* Employee Screens */}
        <Route path="SplashEmployee" element={<SplashEmployee />} />
        <Route path="LoginEmployee" element={<LoginEmployee />} />
        <Route path="EmployeeTaskList" element={<EmployeeTaskList />} />
        <Route path="MyAvailability" element={<MyAvailability />} />

        {/* Super Admin */}
        <Route path="SuperAdminDashboard" element={<SuperAdminDashboard />} />
        <Route path="AddCompany" element={<AddCompany />} />
        <Route path="AddCompanyAdmin" element={<AddCompanyAdmin />} />
        <Route path="EditCompanyAdmin" element={<EditCompanyAdmin />} />
        <Route path="AdminList" element={<AdminList />} />
        <Route path="EditCompany/:id" element={<AddCompany />} />
      </Routes>
    </Router>
  );
}

export default App;

import axios from "axios";
import swal from "sweetalert";
// import { useNavigate } from "react-router-dom";

import React from "react";

//const BASE_URL = "http://localhost:8000/api/v1/";
//const BASE_URL = "https://geekholic.in/api/v1/";
// const BASE_URL = "https://api.tutorvox.com/";
const BASE_URL = "https://api.web911services.com/";
//common service
//post method

export async function makePostLoginRequest(endpoint, bodyFormData, schemaName) {
  // console.log(bodyFormData);

  return await axios({
    method: "post",
    url: BASE_URL + endpoint,
    data: bodyFormData,
    headers: {
      "Content-Type": "application/json",
      typelogin: "todoboom",
      Authorization: `Bearer ${sessionStorage.getItem("accessToken")} ${
        schemaName ? schemaName : sessionStorage.getItem("schema")
      }`,
      "Access-Control-Allow-Origin": "*",
    },
  }).catch(function (error) {
    let errMsg = error.response.data.message;
    console.log("in util", errMsg);
    console.log(error.response.status);
    if (error.response.status == 409) {
      // swal( "Error", `${errMsg}`, "warning", {
      //   timer: 3000,
      //   buttons: false,
      // });
      // throw new Error(errMsg);
      //window.location = "/";
    } else if (error.response.status == 401) {
      swal("" + "Error", `${errMsg}`, "warning", {
        timer: 3000,
        buttons: false,
      });
      localStorage.clear();
      sessionStorage.clear();
      window.location = "/";
    } else throw error;
  });
}

export async function makePostRequest(endpoint, bodyFormData, schemaName) {
  // console.log(bodyFormData);

  return await axios({
    method: "post",
    url: BASE_URL + endpoint,
    data: bodyFormData,
    headers: {
      "Content-Type": "application/json",
      typelogin: "todoboom",
      Authorization: `Bearer ${sessionStorage.getItem("accessToken")} ${
        schemaName ? schemaName : sessionStorage.getItem("schema")
      }`,
      "Access-Control-Allow-Origin": "*",
    },
  }).catch(function (error) {
    let errMsg = error.response.data.message;
    console.log("in util", errMsg);
    console.log(error.response.status);
    if (error.response.status == 409) {
      swal("Error", `${error.response.status}`, "warning", {
        timer: 3000,
        buttons: false,
      });
      //window.location = "/";
    }
    if (error.response.status == 401) {
      swal("" + "Error", `${error.response.status}`, "warning", {
        timer: 3000,
        buttons: false,
      });
      localStorage.clear();
      sessionStorage.clear();
      window.location = "/";
    } else throw error;
  });
}

export async function uploadFileRequest(endpoint, bodyFormData) {
  return axios({
    method: "post",
    url: BASE_URL + endpoint,

    data: bodyFormData,
    headers: {
      "Content-Type": "multipart/form-data",
      typelogin: "todoboom",
      Authorization: `Bearer ${sessionStorage.getItem(
        "accessToken"
      )} ${sessionStorage.getItem("schema")}`,
      "Access-Control-Allow-Origin": "*",
    },
  }).catch(function (error) {
    let errMsg = error.response.data.message;

    console.log(error.response.status);
    if (error.response.status == 413) {
      swal("Error", `${errMsg}`, "warning", {
        timer: 4000,
        buttons: false,
      });
      throw new Error(errMsg);
      //window.location = "/";
    } else if (error.response.status == 401) {
      swal("UnAuthorized User " + "Please Login Again", "", "warning", {
        timer: 2000,
        buttons: false,
      });
      localStorage.clear();
      sessionStorage.clear();
      window.location = "/";
    } else throw error;
  });
}

// get method
export async function makeGetRequest(endpoint, bodyFormData) {
  return axios({
    method: "get",
    url: BASE_URL + endpoint,
    data: bodyFormData,
    headers: {
      "Content-Type": "application/json",
      typelogin: "todoboom",
      Authorization: `Bearer ${sessionStorage.getItem(
        "accessToken"
      )} ${sessionStorage.getItem("schema")}`,
      "Access-Control-Allow-Origin": "*",
    },
  }).catch(function (error) {
    let errMsg = error.response.data.message;

    console.log(error.response.status);
    if (error.response.status == 401) {
      swal("UnAuthorized User " + "Please Login Again", "", "warning", {
        timer: 2000,
        buttons: false,
      });
      localStorage.clear();
      sessionStorage.clear();
      window.location = "/";
    } else throw error;
  });
}

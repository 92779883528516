import React,{ useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Grid, Box } from "@mui/material";
import "react-medium-image-zoom/dist/styles.css";
import AdminBottomNav from "../AdminBottomNav/AdminBottomNav";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";

import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import avtarPic from "../../../assets/images/avtarPic.png";
import noLogo from "../../../assets/images/noLogo.jpg";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../../FormikControl";
import swal from "sweetalert";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import editIco from "../../../assets/images/edit-icon.svg";
import {makeGetRequest, makePostRequest, uploadFileRequest} from '../../../utils/utils'
import { styled } from "@mui/material/styles";
import SuperAdminBottomTab from "./SuperAdminBottomTab";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

function EditCompanyAdmin() {
  const navigate = useNavigate();
  const location = useLocation();
  const { Id } = location.state || {};
  const [showLoader, setShowLoader] = React.useState(false);
  const [formValues, setFormValues] = React.useState(null);
  const [isActive, setIsActive] = useState(true);
  const [isAdmin, setIsAdmin] = useState(true);
  const [companyDropDown, setcompanyDropDown] = React.useState([]);
  const [dropDownSelect, setDropDownSelect] = useState(null);
  const [profileErrMsg, setProfileErrMsg] = React.useState("");
  const [companySchemaName, setCompanySchemaName] = React.useState("");
  const [profilePic, setProfilePic] = React.useState("");
  const [duplicateEmailErrMsg, setDuplicateEmailErrMsg] = React.useState("");
  const [duplicateContactErrMsg, setDuplicateContactErrMsg] = React.useState("");
  const [employeeCodeErrMsg, setEmployeeCodeErrMsg] = React.useState("");
  const [companyErrMsg, setCompanyErrMsg] = React.useState("");
  const [isEditAdmin, setIsEditAdmin] = useState(false);
  
  const initialValues = {
    employee_code: "",
    full_name: "",
    email_id: "",
    contact_no: "",
  };

  const validationSchema = Yup.object({
    employee_code: Yup.string().max(8).required("Required"),
    full_name: Yup.string()
      .trim("White spaces not allow")
      .strict(true)
      .required("Required")
      .matches(
        /^([a-z.A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
        "Numbers/Special Characters Not Allowed!!"
      ),
    email_id: Yup.string()
      .matches(
        /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/,
        "Invalid Email Id"
      )
      .required("Required"),
    contact_no: Yup.string()
      .length(10, "Phone no should be 10 digits long")
      .matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        "Phone number is not valid"
      ).required("Required"),
  });

  async function uploadImageOrFile(fileData) {
    setShowLoader(true);
    const formData = new FormData();
    formData.append("image", fileData);
    var fileUrl;

    await uploadFileRequest("superadmin/upload", formData).then((response) => {
      fileUrl = response.data.imagepath;
    });
    setShowLoader(false);
    return fileUrl;
  }

  async function validateImage(fileData) {
    if (fileData.name.match(/.(jpg|JPG|jpeg|JPEG|png|PNG|gif|GIF)$/i))
      return true;
    else return false;
  }

  async function uploadProfilePic(e) {
    setProfileErrMsg("");

    setShowLoader(true);
    const file = e.target.files[0];
    let validateRes = false;
    if (await validateImage(file)) {
      var logoUrl = await uploadImageOrFile(file);
      setProfilePic(logoUrl);
      setShowLoader(false);
    } else {
      setProfileErrMsg("Invalid file format");
      setShowLoader(false);
    }
    
  }

  const [passwordLength, setPasswordLength] = useState(8);
  const [useSymbols, setUseSymbols] = useState(true);
  const [useNumbers, setUseNumbers] = useState(true);
  const [useLowerCase, setUseLowerCase] = useState(true);
  const [useUpperCase, setUseUpperCase] = useState(true);
  const [upload, setUpload] = React.useState(false);

  const generatePassword = () => {
      
    let charset = "";
    let newPassword = "";

    if (useSymbols) charset += "!@#$%^&*()";
    if (useNumbers) charset += "0123456789";
    if (useLowerCase) charset += "abcdefghijklmnopqrstuvwxyz";
    if (useUpperCase) charset += "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

    for (let i = 0; i < passwordLength; i++) {
        newPassword += charset.charAt(Math.floor(Math.random() * charset.length));
    }
    return newPassword;
  };

  const getAllCompanyList = () => {
    //if (!searchString) searchString = null;
    setShowLoader(true);
    setcompanyDropDown([]);
    
    companyDropDown.length = 0;
    makeGetRequest("superadmin/getcompanylistasc")
      .then((response) => {
        setcompanyDropDown([]);
        let companyassign=[];
        console.log("My editsd===>"+JSON.stringify(response.data.data))

        if (response.data.data) {
          let companyObj;
         
          if (response.data.data.length > 0) {
            
            companyassign.push({ key: "Select Company", value: "" });
            for (let i = 0; i < response.data.data.length; i++) {
              
              companyObj = { key: "", value: "" };
              companyObj.key = response.data.data[i].company_name;
              companyObj.value = response.data.data[i].company_id;
              console.log("company school===>"+JSON.stringify(companyObj));
              companyassign.push(companyObj);
            }
            console.log("Edited compnay===>"+JSON.stringify(companyassign))
            setcompanyDropDown(companyassign);
          } else {
            setcompanyDropDown(companyassign);
          }
          setUpload(!upload);
        }
        setShowLoader(false);
      })
      .catch((err) => {
        setShowLoader(false);
      });
  };

  const statusChangeFun = (status) => {
    setDropDownSelect(status.target.value)
    console.log("Selected Value", status.target.value)
    let idx = status.target.selectedIndex;
    let dataset = status.target.options[idx].dataset;
    setCompanySchemaName(dataset.isd.replaceAll(" ", "").toLowerCase())
    console.log("dataset.isd==>", dataset.isd.replaceAll(" ", "").toLowerCase())
  }

  const UpdateCompanyAdmin = (values) => {
    setShowLoader(true);
    setDuplicateEmailErrMsg("");
    setDuplicateContactErrMsg("");
    setEmployeeCodeErrMsg("");
    setCompanyErrMsg("");

    if (dropDownSelect) {
      if (Id) {
       
        values.company_id = parseInt(dropDownSelect);
        values.schemaname = companySchemaName;
        values.status = isActive;
        values.is_admin = isAdmin;
        if(isAdmin)
          values.user_type = 'Admin';
        else
          values.user_type = 'User';

        values.profile_pic = profilePic;
        delete values.password
       
        makePostRequest("employee/updateemployee", values,values.schemaname)
          .then((response) => {
            setShowLoader(false);
            if (response.data) {
              swal("Success", "Information updated successfully", "success", {
                timer: 3000,
                buttons: false,
              });
              navigate("/AdminList");
            }
          })
          .catch((err) => {
            swal("Warning", err.response.data.message, "warning", {
              timer: 3000,
              buttons: false,
            });
            setShowLoader(false);
          });
      }
    } else {
      setCompanyErrMsg("Please Select Company")
      setShowLoader(false);
    }
  };

  const checkAdminStatus = (status) => {
    console.log("StatusAdmin", status);
    setIsAdmin(status);
  }
  const checkActiveStatus = (status) => {
    console.log("StatusActive", status);
    setIsActive(status);
  }

  React.useEffect(() => {
    if (Id) {
      getAllCompanyList(null)
      makeGetRequest("employee/getemployee/" + Id)
        .then((response) => {
          console.log(response);
          if (response.data) {
            console.log(response.data.data[0]);
            setFormValues(response.data.data[0]);
            setIsAdmin(response.data.data[0].is_admin);
            setIsActive(response.data.data[0].status);
            setCompanySchemaName(response.data.data[0].schemaname)
            setProfilePic(response.data.data[0].profile_pic);
            setDropDownSelect(response.data.data[0].company_id)
          }
        })
        .catch((err) => {
          console.log(err);
          let errMsg = err.response.data.message;
          swal("Warning", errMsg, "warning", {
            timer: 3000,
            buttons: false,
          });
        });
    } else getAllCompanyList(null);
  }, []);

  return (
    <div>
      <div className="wrapper">
        {/* TopBar */}
        <div className="innerPageTopSecBackPatch"></div>
        
        {/* TopBarEnd */}
        <Grid item xs={12} md={12}>
        <div className="innerPageTopSec">
          <div className="forBackArrow">
            <NavigateBeforeIcon onClick={() => navigate(-1)} />
          </div>
          <h1> Edit Company Admin</h1>
          <div className="forBackArrow"></div>
        </div>
        </Grid>
        <div className="addEdiProfDivOut">
          <div className="addEdiProfDivInnrIconAtt">
            <div className="addEdiProfDivInnr">
              {profilePic ? <img src={profilePic} /> : <img src={avtarPic} />}
            </div>
            <Button
              className="profEdiICoDiv"
              component="label"
              role={undefined}
              tabIndex={-1}
            >
              <img src={editIco} />
              <VisuallyHiddenInput
                type="file"
                onChange={(e) => uploadProfilePic(e)}
              />
            </Button>
          </div>
          <span className="errorSt">{profileErrMsg}</span>
        </div>
        <div className="addAdminEmployeeMain">
          <Formik
            initialValues={formValues || initialValues}
            validationSchema={validationSchema}
            onSubmit={UpdateCompanyAdmin}
            enableReinitialize
          >
            {(formik) => (
              <Form>
                <Grid container spacing={2}>
                  
                <Grid item xs={12} md={12}>
                    <FormikControl
                      control="input"
                      type="text"
                      label="Employee Code"
                      placeholder="Employee Code"
                      labelClass="myLable"
                      name="employee_code"
                      className="myInput"
                    />
                    <span className="errorSt">
                      {employeeCodeErrMsg}
                    </span>
                  </Grid>
                  
                  <Grid item xs={12} md={12}>
                    <FormikControl
                      control="input"
                      type="text"
                      label="Full Name"
                      placeholder="Full Name"
                      labelClass="myLable"
                      name="full_name"
                      className="myInput"
                    />
                  </Grid>
                  {isEditAdmin? <Grid item xs={12} md={12}>
                    <FormikControl
                      control="input"
                      type="text"
                      label="Enter Mobile Number"
                      placeholder="Mobile Number"
                      labelClass="myLable"
                      name="contact_no"
                      className="myInput"
                    />
                    <span className="errorSt">
                      {duplicateContactErrMsg}
                    </span>
                  </Grid> :
                  <Grid item xs={12} md={12}>
                    <FormikControl
                      control="input"
                      type="text"
                      label="Enter Mobile Number"
                      placeholder="Mobile Number"
                      labelClass="myLable"
                      name="contact_no"
                      className="myInput"
                    />
                    <span className="errorSt">
                      {duplicateContactErrMsg}
                    </span>
                  </Grid>}
                  <Grid item xs={12} md={12}>
                    <FormikControl
                      control="input"
                      type="text"
                      label="Enter Email"
                      placeholder="Email"
                      labelClass="myLable"
                      name="email_id"
                      className="myInput"
                      //onBlur={(e) => handleEmail(e)}
                    />
                    <span className="errorSt">{duplicateEmailErrMsg}</span>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <select value={dropDownSelect} onChange={statusChangeFun} className="myInput">
                      {companyDropDown.map((catObj) => (
                        <option className="myLable" value={catObj.value} data-isd={catObj.key}>{catObj.key}</option>
                      ))}
                    </select>
                    <span className="errorSt">{companyErrMsg}</span>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <div className="mySwitch">
                      <FormControlLabel
                        control={<Switch checked={isActive} onChange={(e)=>checkActiveStatus(e.target.checked)}/>}
                        label="Active"
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <div className="mySwitch">
                      <FormControlLabel
                        control={<Switch checked={isAdmin} onChange={(e)=>checkAdminStatus(e.target.checked)}/>}
                        label="Is this Admin User?"
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Button type="submit" className="logginBt">Edit Company Admin</Button>
                  </Grid>               
                </Grid>
              </Form>
            )}
          </Formik>
        </div>

        <SuperAdminBottomTab/>
      </div>
      {showLoader ? (
        <div className="loaderNew">
        <div className="loader"></div>
      </div>
      ) : (
        <></>
      )}
    </div>
  )
}

export default EditCompanyAdmin
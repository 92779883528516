import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import profilePic from "../../../assets/images/Profile-Picture.jpg";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import AdminBottomNav from "../AdminBottomNav/AdminBottomNav";
import SortIcon from "@mui/icons-material/Sort";
import FilterIcon from "@mui/icons-material/Filter";
import { Button, Grid, Box, colors } from "@mui/material";
import * as Yup from "yup";
import moment from "moment/moment";

// For Modal
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
// End

import afterBef1 from "../../../assets/images/afterBef1.png";
import afterBef2 from "../../../assets/images/afterBef2.png";
import CloseIcon from "@mui/icons-material/Close";
import calendarIm from "../../../assets/images/calendar.png";

import Checkbox from "@mui/material/Checkbox";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import swal from "sweetalert";
import { makeGetRequest, makePostRequest } from "../../../utils/utils";
import ToggleButton from "@mui/material/ToggleButton";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import VerifiedIcon from "@mui/icons-material/Verified";
import AppDrawer from "../../AppDrawer";

import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

/* const useStyles = makeStyles((theme)=>({
  formWrapper:{
    marginTop:theme.spacing(5),
    marginBottom:theme.spacing(8),
  },
})); */

function WeeklyTasks() {
  const navigate = useNavigate();
  const [todaysDate, setTodaysDate] = useState(Date());
  const [currentIndex, setCurrentIndex] = useState(0);
  // For Modal
  const [open, setOpen] = React.useState(false);
  const [isSelectAllChecked, setIsSelectAllChecked] = useState(false);
  const [checkedRows, setCheckedRows] = useState({});
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  // End

  const startwDate = moment("2024-09-23");
  const endwDate = moment().endOf("year").add(1, "year");

  const datesArrayVal = getMondaysArray(startwDate, endwDate);
  console.log("my monday array===>" + JSON.stringify(datesArrayVal));
  const today = getStartWeek();

  const currentDate = moment().format("YYYY-MM-DD");

  const getCurrentWeekStart = () => {
    const today = moment();
    const isSunday = today.day() === 0; // 0 = Sunday
    const currentWeekStart = isSunday ? today.day(-6) : today.startOf("week");
    return currentWeekStart;
  };

  //Please check this function if today is Monday then it is getting today's Monday but if are is in midweek then it shows previuous monday but next day then sometimes it shows another Monday
  const currentWeekStart = getStartWeek();
  const formattedDate = currentWeekStart;
  console.log("sssssss" + formattedDate);

  const todayIndex = datesArrayVal.findIndex((arraydate) =>
    moment(arraydate.date).isSame(formattedDate, "day")
  );

  const settings = {
    onReInit: () => setCurrentSlide(slider1?.innerSlider.state.currentSlide),
    initialSlide: todayIndex,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    // arrows: true,
    dots: false,
    speed: 300,
    centerPadding: "20px",
    infinite: true,
    autoplaySpeed: 5000,
    // nextArrow: <NextArrow />,
  };

  // State for the table rows
  const [rows, setRows] = useState([
    {
      id: 1,
      selectAll: false,
      checkboxes: [false, false, false, false, false, false, false],
    },
    {
      id: 2,
      selectAll: false,
      checkboxes: [false, false, false, false, false, false, false],
    },
    {
      id: 3,
      selectAll: false,
      checkboxes: [false, false, false, false, false, false, false],
    },
  ]);

  // Toggle the "select all" checkboxes in a row
  // const handleSelectAllChange = (rowId) => {
  //   setRows(
  //     rows.map((row) =>
  //       row.id === rowId
  //         ? {
  //             ...row,
  //             selectAll: !row.selectAll,
  //             checkboxes: row.checkboxes.map(() => !row.selectAll),
  //           }
  //         : row
  //     )
  //   );
  // };


  const handleSelectAllChange = (e,index,employeeid,categoryid,arrDate) => 
    {
      const isChecked = e.target.checked;
      setIsSelectAllChecked(isChecked);
      setCheckedRows((prev) => ({ ...prev, [categoryid]: !prev[categoryid] }));

      const weekDay = [
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
        'Sunday'
      ];

      let tempList = [];
    if (weekDay.includes("Monday") ) {
      if(arrDate[0].CurrentWeek >=currentDate)
      {
        
      tempList = [...selectedMenuOfMonday.categoryid];
      if (tempList.length == 0) {
        selectedMenuOfMonday.schedule_date = arrDate[0].CurrentWeek;
        selectedMenuOfMonday.employeeid = employeeid;
        selectedMenuOfMonday.weekday = weekDay[0];
        selectedMenuOfMonday.status = true;
      }
    
      if (isChecked == true) {
        
          if (!tempList.includes(categoryid)) {
            tempList.push(categoryid);
          }
        document.getElementById("mon" + index + "" + employeeid + "" + categoryid).setAttribute("checked", true);
      } else {
      
        tempList.splice(tempList.indexOf(categoryid), 1);
        document
          .getElementById("mon" + index + "" + employeeid + "" + categoryid)
          .removeAttribute("checked", false);
      }

      selectedMenuOfMonday.categoryid = tempList;
      setSelectedMenuOfMonday(selectedMenuOfMonday);
      setUpload(!upload);
      }
    } 
    
    if (weekDay.includes("Tuesday")) {

      if(arrDate[1].CurrentWeek >=currentDate)
        {
      tempList = [...selectedScheduleOfTuesday.categoryid];
      if (tempList.length == 0) {
        selectedScheduleOfTuesday.schedule_date = arrDate[1].CurrentWeek;
        selectedScheduleOfTuesday.employeeid = employeeid;
        selectedScheduleOfTuesday.weekday = weekDay[1];
        selectedScheduleOfTuesday.status = true;
      }

      if (isChecked == true) {
        if (!tempList.includes(categoryid)) {
          tempList.push(categoryid);
        }
        document
          .getElementById("tues" + index + "" + employeeid + "" + categoryid)
          .setAttribute("checked", true);
      } else {
        tempList.splice(tempList.indexOf(categoryid), 1);
        document
          .getElementById("tues" + index + "" + employeeid + "" + categoryid)
          .removeAttribute("checked", false);
      }

      selectedScheduleOfTuesday.categoryid = tempList;
      setSelectedScheduleOfTuesday(selectedScheduleOfTuesday);
      setUpload(!upload);
      }
    }  
    
    if (weekDay.includes("Wednesday")) {
     
      if(arrDate[2].CurrentWeek >=currentDate)
        {
      tempList = [...selectedMenuOfWednesday.categoryid];

      if (tempList.length == 0) {
        selectedMenuOfWednesday.schedule_date = arrDate[2].CurrentWeek;
        selectedMenuOfWednesday.employeeid = employeeid;
        selectedMenuOfWednesday.weekday = weekDay[2];
        selectedMenuOfWednesday.status = true;
      }

      if (isChecked == true) {
        if (!tempList.includes(categoryid)) {
          tempList.push(categoryid);
        }
        document
          .getElementById("wed" + index + "" + employeeid + "" + categoryid)
          .setAttribute("checked", true);
      } else {
        tempList.splice(tempList.indexOf(categoryid), 1);
        document
          .getElementById("wed" + index + "" + employeeid + "" + categoryid)
          .removeAttribute("checked", false);
      }

      selectedMenuOfWednesday.categoryid = tempList;
      setSelectedMenuOfWednesday(selectedMenuOfWednesday);
      setUpload(!upload);

    }
    } 
    
    if (weekDay.includes("Thursday")) {
      if(arrDate[3].CurrentWeek >=currentDate)
        {
      tempList = [...selectedMenuOfThursday.categoryid];

      if (tempList.length == 0) {
        selectedMenuOfThursday.schedule_date = arrDate[3].CurrentWeek;
        selectedMenuOfThursday.employeeid = employeeid;
        selectedMenuOfThursday.weekday = weekDay[3];
        selectedMenuOfThursday.status = true;
      }

      if (isChecked == true) {
        if (!tempList.includes(categoryid)) {
          tempList.push(categoryid);
        }
        document
          .getElementById("thur" + index + "" + employeeid + "" + categoryid)
          .setAttribute("checked", true);
      } else {
        tempList.splice(tempList.indexOf(categoryid), 1);
        document
          .getElementById("thur" + index + "" + employeeid + "" + categoryid)
          .removeAttribute("checked", false);
      }

      selectedMenuOfThursday.categoryid = tempList;
      setSelectedMenuOfThursday(selectedMenuOfThursday);
      setUpload(!upload);

      }
    }  
    
    if (weekDay.includes("Friday")) {
      if(arrDate[4].CurrentWeek >=currentDate)
        {
      tempList = [...selectedMenuOfFriday.categoryid];

      if (tempList.length == 0) {
        selectedMenuOfFriday.schedule_date = arrDate[4].CurrentWeek;
        selectedMenuOfFriday.employeeid = employeeid;
        selectedMenuOfFriday.weekday = weekDay[4];
        selectedMenuOfFriday.status = true;
      }
      if (isChecked == true) {
        if (!tempList.includes(categoryid)) {
          tempList.push(categoryid);
        }
        document
          .getElementById("fri" + index + "" + employeeid + "" + categoryid)
          .setAttribute("checked", true);
      } else {
        tempList.splice(tempList.indexOf(categoryid), 1);
        document
          .getElementById("fri" + index + "" + employeeid + "" + categoryid)
          .removeAttribute("checked", false);
      }

      selectedMenuOfFriday.categoryid = tempList;
      setSelectedMenuOfFriday(selectedMenuOfFriday);
      setUpload(!upload);
    }
  }  
    
    if (weekDay.includes("Saturday")) {
      if(arrDate[5].CurrentWeek >=currentDate)
        {
      tempList = [...selectedMenuOfSaturday.categoryid];

      if (tempList.length == 0) {
        selectedMenuOfSaturday.schedule_date = arrDate[5].CurrentWeek;
        selectedMenuOfSaturday.employeeid = employeeid;
        selectedMenuOfSaturday.weekday = weekDay[5];
        selectedMenuOfSaturday.status = true;
      }
      if (isChecked == true) {
        if (!tempList.includes(categoryid)) {
          tempList.push(categoryid);
        }
        document
          .getElementById("sat" + index + "" + employeeid + "" + categoryid)
          .setAttribute("checked", true);
      } else {
        tempList.splice(tempList.indexOf(categoryid), 1);
        document
          .getElementById("sat" + index + "" + employeeid + "" + categoryid)
          .removeAttribute("checked", false);
      }

      selectedMenuOfSaturday.categoryid = tempList;
      setSelectedMenuOfSaturday(selectedMenuOfSaturday);
      setUpload(!upload);
    } 
  } 
    
    if (weekDay.includes("Sunday")) {
      if(arrDate[6].CurrentWeek >=currentDate)
        {
      tempList = [...selectedMenuOfSunday.categoryid];

      if (tempList.length == 0) {
        selectedMenuOfSunday.schedule_date = arrDate[6].CurrentWeek;
        selectedMenuOfSunday.employeeid = employeeid;
        selectedMenuOfSunday.weekday = weekDay[6];
        selectedMenuOfSunday.status = true;
      }
      if (isChecked == true) {
        if (!tempList.includes(categoryid)) {
          tempList.push(categoryid);
        }
        document
          .getElementById("sunday" + index + "" + employeeid + "" + categoryid)
          .setAttribute("checked", true);
      } else {
        tempList.splice(tempList.indexOf(categoryid), 1);
        document
          .getElementById("sunday" + index + "" + employeeid + "" + categoryid)
          .removeAttribute("checked".false);
      }

      selectedMenuOfSunday.categoryid = tempList;
      setSelectedMenuOfSunday(selectedMenuOfSunday);
      setUpload(!upload);
    }
  }


    }

  function getMonday(d) {
    var date = new Date(d);
    const day = date.getDay();
    const diff = date.getDate() - day + (day === 0 ? -6 : 1);
    return new Date(d.setDate(diff));
  }

  function getEntireWeek() {
    let week = [];
    var d = new Date();
    d.setDate(1);
    var curr = getMonday(new Date());
    var xday = curr.getDate();
    d.setDate(xday);

    for (let i = 1; i <= 7; i++) {
      let first = curr.getDate() - curr.getDay() + i;
      let day = new Date(curr.setDate(first)).toISOString().slice(0, 10);
      week.push({ CurrentWeek: day });
    }
    return week;
  }

  function getStartWeek() {
    let week = [];
    var d = new Date();
    d.setDate(1);
    var curr = getMonday(new Date());
    var xday = curr.getDate();
    d.setDate(xday);
    let first = curr.getDate() - curr.getDay() + 1;
    let day = new Date(curr.setDate(first)).toISOString().slice(0, 10);
    return day;
  }

  function getArrowDatesArray() {
    const dates = [];
    const startcDate = moment("2024-09-23");
    const endcDate = moment().endOf("year").add(1, "year");

    for (
      let date = moment(startcDate);
      date.isSameOrBefore(endcDate);
      date.add(1, "day")
    ) {
      if (date.day() === 1) {
        // 1 represents Monday
        const d = date.toDate();
        var newDates = d.getDate();
        var newmonths = d.getMonth() + 1;
        var years = d.getFullYear();

        if (newDates < 10 && newDates > 0) newDates = "0" + newDates;

        if (newmonths < 10 && newmonths > 0) newmonths = "0" + newmonths;

        dates.push(years + "-" + newmonths + "-" + newDates);
      }
    }

    return dates;
  }

  function getEndWeek() {
    let week = [];
    var d = new Date();
    d.setDate(1);
    var curr = getMonday(new Date());
    var xday = curr.getDate();
    d.setDate(xday);
    let first = curr.getDate() - curr.getDay() + 7;
    let day = new Date(curr.setDate(first)).toISOString().slice(0, 10);
    return day;
  }

  function getAllMondays(startDate, endDate) {
    const mondays = [];
    for (
      let date = moment(startDate);
      date.isSameOrBefore(endDate);
      date.add(1, "day")
    ) {
      if (date.day() === 1) {
        // 1 represents Monday
        const d = date.toDate();
        var newDates = d.getDate();
        var newmonths = d.getMonth() + 1;
        var years = d.getFullYear();

        if (newDates < 10 && newDates > 0) newDates = "0" + newDates;

        if (newmonths < 10 && newmonths > 0) newmonths = "0" + newmonths;

        mondays.push({ Mondaydate: newmonths + "-" + newDates + "-" + years });
        //console.log("SSSSS"+JSON.stringify(mondays))
      }
    }
    //console.log("My Mondays===>"+JSON.stringify(mondays))
    return mondays;
  }

  function getMondaysArray(startDate, endDate) {
    const mondays = [];
    for (
      let date = moment(startDate);
      date.isSameOrBefore(endDate);
      date.add(1, "day")
    ) {
      if (date.day() === 1) {
        // 1 represents Monday
        const d = date.toDate();
        var newDates = d.getDate();
        var newmonths = d.getMonth() + 1;
        var years = d.getFullYear();

        if (newDates < 10 && newDates > 0) newDates = "0" + newDates;

        if (newmonths < 10 && newmonths > 0) newmonths = "0" + newmonths;

        mondays.push({ date: years + "-" + newmonths + "-" + newDates });
      }
    }
    console.log("My Mondays===>" + JSON.stringify(mondays));
    return mondays;
  }

  // Example usage:

  // Get all Mondays in year from provided date
  // Default today's date
  function getMondays(d) {
    d = d ? new Date(+d) : new Date();
    d.setDate(1);

    var endYear = 2024 + 10;
    var endMonth = d.getMonth();

    // Set to first Monday

    var day = getMonday(new Date());
    var xday = day.getDate();

    d.setDate(xday);
    var mondays = [];

    // Create Dates for all Mondays up to end year and month
    while (d.getFullYear() < endYear || d.getMonth() != endMonth) {
      var pushDate = new Date(d.getTime());
      var newDates = pushDate.getDate();

      if (newDates < 10 && newDates > 0) newDates = "0" + newDates;
      var newMonth = pushDate.getMonth() + 1;
      if (newMonth < 10 && newMonth > 0) newMonth = "0" + newMonth;
      mondays.push({
        Mondaydate: newMonth + "-" + newDates + "-" + pushDate.getFullYear(),
      });
      d.setDate(d.getDate() + 7);
    }
    console.log("new my mondays===>" + JSON.stringify(mondays));
    return mondays;
  }

  // Get all Mondays and display result

  function manageDate(dates) {
    var arr1 = dates.split("-");
    var newDates = arr1[2];
    if (parseInt(newDates) < 10 && parseInt(newDates) > 0)
      newDates = "0" + parseInt(newDates);
    return newDates;
  }

  function add(arr, name) {
    if (arr != undefined) {
      const { length } = arr;
      const id = length + 1;
      const found = arr.some((el) => el.employeeid === name);
      let res = false;
      if (!found) {
        res = false;
      } else {
        res = true;
      }
      return res;
    }
  }

  // Toggle individual checkbox
  const handleCheckboxChange = (rowId, index) => {
    setRows(
      rows.map((row) =>
        row.id === rowId
          ? {
              ...row,
              checkboxes: row.checkboxes.map((checked, i) =>
                i === index ? !checked : checked
              ),
              selectAll: row.checkboxes.every((checked, i) =>
                i === index ? !checked : checked
              ),
            }
          : row
      )
    );
  };

  const handleChangeWeekDayCheckBox = (isChecked,index,employeeid,weekDay,categoryid,arrDate
  ) => {
    
    console.log(weekDay);
    let tempList = [];
    if (weekDay == "Monday") {
      tempList = [...selectedMenuOfMonday.categoryid];
      if (tempList.length == 0) {
        selectedMenuOfMonday.schedule_date = arrDate[0].CurrentWeek;
        selectedMenuOfMonday.employeeid = employeeid;
        selectedMenuOfMonday.weekday = weekDay;
        selectedMenuOfMonday.status = true;
      }
      if (isChecked == true) {
        tempList.push(categoryid);
        document
          .getElementById("mon" + index + "" + employeeid + "" + categoryid)
          .setAttribute("checked", true);
      } else {
        tempList.splice(tempList.indexOf(categoryid), 1);
        document
          .getElementById("mon" + index + "" + employeeid + "" + categoryid)
          .removeAttribute("checked", false);
      }

      selectedMenuOfMonday.categoryid = tempList;
      setSelectedMenuOfMonday(selectedMenuOfMonday);
      setUpload(!upload);
    } else if (weekDay == "Tuesday") {
      tempList = [...selectedScheduleOfTuesday.categoryid];
      if (tempList.length == 0) {
        selectedScheduleOfTuesday.schedule_date = arrDate[1].CurrentWeek;
        selectedScheduleOfTuesday.employeeid = employeeid;
        selectedScheduleOfTuesday.weekday = weekDay;
        selectedScheduleOfTuesday.status = true;
      }

      if (isChecked == true) {
        tempList.push(categoryid);
        document
          .getElementById("tues" + index + "" + employeeid + "" + categoryid)
          .setAttribute("checked", true);
      } else {
        tempList.splice(tempList.indexOf(categoryid), 1);
        document
          .getElementById("tues" + index + "" + employeeid + "" + categoryid)
          .removeAttribute("checked", false);
      }

      selectedScheduleOfTuesday.categoryid = tempList;
      setSelectedScheduleOfTuesday(selectedScheduleOfTuesday);
      setUpload(!upload);
    } else if (weekDay == "Wednesday") {
      tempList = [...selectedMenuOfWednesday.categoryid];

      if (tempList.length == 0) {
        selectedMenuOfWednesday.schedule_date = arrDate[2].CurrentWeek;
        selectedMenuOfWednesday.employeeid = employeeid;
        selectedMenuOfWednesday.weekday = weekDay;
        selectedMenuOfWednesday.status = true;
      }

      if (isChecked == true) {
        tempList.push(categoryid);
        document
          .getElementById("wed" + index + "" + employeeid + "" + categoryid)
          .setAttribute("checked", true);
      } else {
        tempList.splice(tempList.indexOf(categoryid), 1);
        document
          .getElementById("wed" + index + "" + employeeid + "" + categoryid)
          .removeAttribute("checked", false);
      }

      selectedMenuOfWednesday.categoryid = tempList;
      setSelectedMenuOfWednesday(selectedMenuOfWednesday);
      setUpload(!upload);
    } else if (weekDay == "Thursday") {
      tempList = [...selectedMenuOfThursday.categoryid];

      if (tempList.length == 0) {
        selectedMenuOfThursday.schedule_date = arrDate[3].CurrentWeek;
        selectedMenuOfThursday.employeeid = employeeid;
        selectedMenuOfThursday.weekday = weekDay;
        selectedMenuOfThursday.status = true;
      }

      if (isChecked == true) {
        tempList.push(categoryid);
        document
          .getElementById("thur" + index + "" + employeeid + "" + categoryid)
          .setAttribute("checked", true);
      } else {
        tempList.splice(tempList.indexOf(categoryid), 1);
        document
          .getElementById("thur" + index + "" + employeeid + "" + categoryid)
          .removeAttribute("checked", false);
      }

      selectedMenuOfThursday.categoryid = tempList;
      setSelectedMenuOfThursday(selectedMenuOfThursday);
      setUpload(!upload);
    } else if (weekDay == "Friday") {
      tempList = [...selectedMenuOfFriday.categoryid];

      if (tempList.length == 0) {
        selectedMenuOfFriday.schedule_date = arrDate[4].CurrentWeek;
        selectedMenuOfFriday.employeeid = employeeid;
        selectedMenuOfFriday.weekday = weekDay;
        selectedMenuOfFriday.status = true;
      }
      if (isChecked == true) {
        tempList.push(categoryid);
        document
          .getElementById("fri" + index + "" + employeeid + "" + categoryid)
          .setAttribute("checked", true);
      } else {
        tempList.splice(tempList.indexOf(categoryid), 1);
        document
          .getElementById("fri" + index + "" + employeeid + "" + categoryid)
          .removeAttribute("checked", false);
      }

      selectedMenuOfFriday.categoryid = tempList;
      setSelectedMenuOfFriday(selectedMenuOfFriday);
      setUpload(!upload);
    } else if (weekDay == "Saturday") {
      tempList = [...selectedMenuOfSaturday.categoryid];

      if (tempList.length == 0) {
        selectedMenuOfSaturday.schedule_date = arrDate[5].CurrentWeek;
        selectedMenuOfSaturday.employeeid = employeeid;
        selectedMenuOfSaturday.weekday = weekDay;
        selectedMenuOfSaturday.status = true;
      }
      if (isChecked == true) {
        tempList.push(categoryid);
        document
          .getElementById("sat" + index + "" + employeeid + "" + categoryid)
          .setAttribute("checked", true);
      } else {
        tempList.splice(tempList.indexOf(categoryid), 1);
        document
          .getElementById("sat" + index + "" + employeeid + "" + categoryid)
          .removeAttribute("checked", false);
      }

      selectedMenuOfSaturday.categoryid = tempList;
      setSelectedMenuOfSaturday(selectedMenuOfSaturday);
      setUpload(!upload);
    } else if (weekDay == "Sunday") {
      tempList = [...selectedMenuOfSunday.categoryid];

      if (tempList.length == 0) {
        selectedMenuOfSunday.schedule_date = arrDate[6].CurrentWeek;
        selectedMenuOfSunday.employeeid = employeeid;
        selectedMenuOfSunday.weekday = weekDay;
        selectedMenuOfSunday.status = true;
      }
      if (isChecked == true) {
        tempList.push(categoryid);
        document
          .getElementById("sunday" + index + "" + employeeid + "" + categoryid)
          .setAttribute("checked", true);
      } else {
        tempList.splice(tempList.indexOf(categoryid), 1);
        document
          .getElementById("sunday" + index + "" + employeeid + "" + categoryid)
          .removeAttribute("checked".false);
      }

      selectedMenuOfSunday.categoryid = tempList;
      setSelectedMenuOfSunday(selectedMenuOfSunday);
      setUpload(!upload);
    }
  };

  const handleCloseShipTiming = () => {
    setOpenShipTiming(false);
  };
  // End

  const handleClick = async (id, frequancyid) => {
    setClickedId(id);
    handleCloseShipTiming();
    setShowLoader(true);
    setLoginErrMsg("");

    let freid = [];
    freid.push(frequancyid);

    const initialValues = {
      taskid: taskid,
      categoryid: categoryid,
      frequancyid: freid,
      status: true,
      is_deleted: false,
    };

    await makePostRequest("schedular/insertschedular", initialValues)
      .then((response) => {
        if (response.data) {
          setLoginErrMsg("");
          setShowLoader(false);
        }
      })
      .catch((err) => {
        let errMsg = err.message;
        setShowLoader(false);
        setLoginErrMsg(errMsg);
      });
  };

  // For Modal
  const [ShipTiming, setOpenShipTiming] = React.useState(false);

  const handleClickOpenShipTiming = () => {
    setOpenShipTiming(true);
  };

  const getSelectCount = () => {
    setShowLoader(true);
    //setCategoryID(id)

    makeGetRequest(
      "employeeschedular/getschedularCount/'" +
        startDate +
        "'/'" +
        endDate +
        "'"
    )
      .then((response) => {
        if (response.data.data) {
          if (response.data.data.res.rows.length > 0) {
            setSelectedTaskList(response.data.data.res.rows);
            setIncValue(response.data.data.totalCount);
            setShowLoader(false);
          } else {
            setSelectedTaskList();
            setIncValue(0);
            setShowLoader(false);
          }
          setUpload(!upload);
        }
      })
      .catch((err) => {
        setShowLoader(false);
      });
  };

  const getSelectSliderCount = (startDate, endDate) => {
    setShowLoader(true);

    makeGetRequest(
      "employeeschedular/getschedularCount/'" +
        startDate +
        "'/'" +
        endDate +
        "'"
    )
      .then((response) => {
        if (response.data.data) {
          if (response.data.data.res.rows.length > 0) {
            setSelectedTaskList(response.data.data.res.rows);
            setIncValue(response.data.data.totalCount);
            setShowLoader(false);
          } else {
            setSelectedTaskList();
            setIncValue(0);
            setShowLoader(false);
          }
          setUpload(!upload);
        }
      })
      .catch((err) => {
        setShowLoader(false);
      });
  };

  const [value, setValue] = React.useState(0);
  const [incvalue, setIncValue] = React.useState(0);
  const [taskList, setTaskList] = React.useState([]);
  const [upload, setUpload] = React.useState(false);
  const [sortBy, setSortBy] = React.useState("asc");
  const [tempSlide, setTempSlide] = React.useState("");
  const [sortColumnName, setSortColumnName] = React.useState("full_name");
  const [selected, setSelected] = React.useState(false);
  const [freequancyList, setFreequancyList] = React.useState([]);
  const [categoryList, setCategoryList] = React.useState([]);
  const [categoryid, setCategoryID] = React.useState([]);
  const [taskid, setTaskID] = React.useState(0);
  const [mondaysCal, setMondaysCal] = React.useState([]);
  const [currentweekCal, setCurrentWeekCal] = React.useState([]);
  const [startDate, setStartDate] = React.useState(getStartWeek());
  const [endDate, setEndDate] = React.useState(getEndWeek());
  const [clickedId, setClickedId] = useState(null);
  const labelCheck = { inputProps: { "aria-label": "Checkbox demo" } };
  const [toggleId, setToggleId] = useState(null);
  const [selectedMenuItemList, setSelectedMenuItemList] = React.useState([]);
  const [selectedTaskList, setSelectedTaskList] = React.useState();

  const [nav1, setNav1] = useState(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [slider1, setSlider1] = useState(null);

  const [selectedMenuOfSunday, setSelectedMenuOfSunday] = React.useState({
    schedule_date: "",
    employeeid: "",
    categoryid: [],
    weekday: "",
    status: "",
  });

  const [selectedMenuOfMonday, setSelectedMenuOfMonday] = React.useState({
    schedule_date: "",
    employeeid: "",
    categoryid: [],
    weekday: "",
    status: "",
  });

  const [selectedScheduleOfTuesday, setSelectedScheduleOfTuesday] =
    React.useState({
      schedule_date: "",
      employeeid: "",
      categoryid: [],
      weekday: "",
      status: "",
    });

  const [selectedMenuOfWednesday, setSelectedMenuOfWednesday] = React.useState({
    schedule_date: "",
    employeeid: "",
    categoryid: [],
    weekday: "",
    status: "",
  });
  const [selectedMenuOfThursday, setSelectedMenuOfThursday] = React.useState({
    schedule_date: "",
    employeeid: "",
    categoryid: [],
    weekday: "",
    status: "",
  });
  const [selectedMenuOfFriday, setSelectedMenuOfFriday] = React.useState({
    schedule_date: "",
    employeeid: "",
    categoryid: [],
    weekday: "",
    status: "",
  });
  const [selectedMenuOfSaturday, setSelectedMenuOfSaturday] = React.useState({
    schedule_date: "",
    employeeid: "",
    categoryid: [],
    weekday: "",
    status: "",
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setTempSlide("");
  };

  async function updateSchedular(e, index, id) {
    // alert(startDate)
    // alert(endDate);

    setShowLoader(true);
    if (selectedMenuOfMonday.categoryid.length > 0) {
      selectedMenuItemList.push(selectedMenuOfMonday);
      setSelectedMenuItemList(selectedMenuItemList);
    }
    if (selectedScheduleOfTuesday.categoryid.length > 0) {
      selectedMenuItemList.push(selectedScheduleOfTuesday);
      setSelectedMenuItemList(selectedMenuItemList);
    }
    if (selectedMenuOfWednesday.categoryid.length > 0) {
      selectedMenuItemList.push(selectedMenuOfWednesday);
      setSelectedMenuItemList(selectedMenuItemList);
    }
    if (selectedMenuOfThursday.categoryid.length > 0) {
      selectedMenuItemList.push(selectedMenuOfThursday);
      setSelectedMenuItemList(selectedMenuItemList);
    }
    if (selectedMenuOfFriday.categoryid.length > 0) {
      selectedMenuItemList.push(selectedMenuOfFriday);
      setSelectedMenuItemList(selectedMenuItemList);
    }
    if (selectedMenuOfSaturday.categoryid.length > 0) {
      selectedMenuItemList.push(selectedMenuOfSaturday);
      setSelectedMenuItemList(selectedMenuItemList);
    }
    if (selectedMenuOfSunday.categoryid.length > 0) {
      selectedMenuItemList.push(selectedMenuOfSunday);
      setSelectedMenuItemList(selectedMenuItemList);
    }

    await makeGetRequest(
      "employeeschedular/deleteschedular/" +
        id +
        "/'" +
        startDate +
        "'/'" +
        endDate +
        "'"
    )
      .then((response) => {
        console.log("delete", response);
      })
      .catch((err) => {});

    if (selectedMenuItemList.length > 0) {
      for (var i = 0; i < selectedMenuItemList.length; i++) {
        // if (!selectedMenuItemList[i].categoryid)
        //   {
        //       await makePostRequest("employeeschedular/updatetaskschedular",selectedMenuItemList[i]).then((response) => {
        //         if (response.data.data)
        //           {
        //             setSelectedMenuItemList([])

        //           }
        //       }).catch((err) => {

        //       });
        //   }
        //   else
        //   {

        await makePostRequest(
          "employeeschedular/inserttaskschedular",
          selectedMenuItemList[i]
        )
          .then((response) => {
            if (response.data.data) {
              setSelectedMenuItemList([]);
            }
          })
          .catch((err) => {});
        //}
      }

      getSelectCount();
      swal(
        "Success",
        "The employee schedular has added successfully",
        "success",
        {
          timer: 3000,
          buttons: false,
        }
      );

      setShowLoader(false);
    } else {
      setShowLoader(false);
    }
  }

  function endOfWeek(date) {
    var lastday = date.getDate() - (date.getDay() - 1) + 7;
    return new Date(date.setDate(lastday)).toISOString().slice(0, 10);
  }

  function getSelectedWeek(startDate) {
    let week = [];
    var newdates = new Date(startDate);
    var d = new Date(startDate);
    d.setDate(1);
    var curr = getMonday(newdates);
    var xday = curr.getDate();
    d.setDate(xday);

    for (let i = 1; i <= 7; i++) {
      let first = curr.getDate() - curr.getDay() + i;
      let day = new Date(curr.setDate(first)).toISOString().slice(0, 10);
      week.push({ CurrentWeek: day });
    }
    setCurrentWeekCal(week);
  }

  const handleArrowClick = (e, direction) => {
    let newIndex = 0;
    let additionDate;

    if (currentIndex == 0) {
      const datesArrayVal = getArrowDatesArray(startDate, endDate);

      newIndex = direction === "next" ? currentIndex + 1 : currentIndex - 1;
      setCurrentIndex(newIndex);
      direction === "next"
        ? (additionDate = datesArrayVal[newIndex])
        : (additionDate = datesArrayVal[newIndex]);

      const date = moment(additionDate);
      const formattedDate = date.format("MM-DD-YYYY");
      SelectSliders(newIndex, formattedDate);
    } else {
      const datesArrayVal = getArrowDatesArray(startDate, endDate);

      newIndex = direction === "next" ? currentIndex + 1 : currentIndex - 1;
      setCurrentIndex(newIndex);
      direction === "next"
        ? (additionDate = datesArrayVal[newIndex])
        : (additionDate = datesArrayVal[newIndex]);
      const date = moment(additionDate);
      const formattedDate = date.format("MM-DD-YYYY");
      SelectSliders(newIndex, formattedDate);
    }
  };

  function SelectSliders(idx, obs) {
    var element = document.getElementById("listID-" + tempSlide);
    if (element != null) {
      element.classList.toggle("selectedList");
    }

    var elementexp = document.getElementById("expandId-" + tempSlide);
    if (elementexp != null) {
      elementexp.classList.toggle("expanded");
    }

    setTaskID();
    setTempSlide();

    var newdates = new Date(obs);
    var curr = getMonday(newdates);
    //please check this function again
    let first = curr.getDate() - curr.getDay() + 2;
    let day = new Date(curr.setDate(first)).toISOString().slice(0, 10);
    let lastdays = endOfWeek(curr);
    slider1?.slickGoTo(idx);
    setStartDate(day);
    setEndDate(lastdays);
    getSelectSliderCount(day, lastdays);
    getSelectedWeek(day);
  }

  async function forSelectList(index, id) {
    setTaskID(id);
    setTempSlide(index + "" + id);

    let results = add(selectedTaskList, taskid);
    if (results == false) {
      var element = document.getElementById("listID-" + tempSlide);
      if (element != null) {
        element.classList.toggle("selectedList");
      }

      var elementexp = document.getElementById("expandId-" + tempSlide);
      if (elementexp != null) {
        elementexp.classList.toggle("expanded");
      }
    } else if (results == true) {
      var elementexp = document.getElementById("expandId-" + tempSlide);
      if (elementexp != null) {
        elementexp.classList.toggle("expanded");
      }
    } else {
      var element = document.getElementById("listID-" + tempSlide);
      if (element != null) {
        element.classList.toggle("selectedList");
      }

      var elementexp = document.getElementById("expandId-" + tempSlide);
      if (elementexp != null) {
        elementexp.classList.toggle("expanded");
      }
    }

    setToggleId(index);
    setShowLoader(true);

    await getselectedSchedular(id, startDate, endDate);

    var element = document.getElementById("listID-" + index + id);
    element.classList.add("selectedList");

    var elementexp = document.getElementById("expandId-" + index + id);
    elementexp.classList.toggle("expanded");

    setTimeout(() => {
      setShowLoader(false);
    }, 1000);
  }

  function a11yProps(index, ids) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  /*  const useStyles = makeStyles((theme)=>({
          formWrapper:{
            marginTop:theme.spacing(5),
            marginBottom:theme.spacing(8),
          },
        })); */

  const [showLoader, setShowLoader] = React.useState(false);
  const [loginErrMsg, setLoginErrMsg] = React.useState("");

  const initialValues = {
    category_name: "",
  };

  const onSubmit = async (values) => {};

  function handleChangeSortBy() {
    setSortColumnName("full_name");
    setSelected(!selected);
    if (sortBy == "desc") setSortBy("asc");
    else setSortBy("desc");
    setUpload(!upload);
    getAllTaskList(null);
  }

  const validationSchema = Yup.object({
    category_name: Yup.string().required("Required"),
  });

  async function getAllTaskList(searchString) {
    if (!searchString) searchString = null;
    setShowLoader(true);

    await makeGetRequest(
      "employee/getemployeelist/" +
        localStorage.getItem("companyId") +
        "/" +
        sortColumnName +
        "/" +
        sortBy +
        "/" +
        searchString
    )
      .then((response) => {
        if (response.data.data) {
          if (response.data.data.length > 0) {
            setTaskList(response.data.data);
            getSelectCount();
          } else setTaskList([]);
          setUpload(!upload);
        }
        setShowLoader(false);
      })
      .catch((err) => {
        setShowLoader(false);
      });
  }

  async function getselectedSchedular(empid, startDate, endDate) {
    //setShowLoader(true);

    setSelectedMenuOfMonday({
      schedule_date: "",
      employeeid: "",
      categoryid: [],
      weekday: "",
      status: "",
    });

    setSelectedMenuOfSunday({
      schedule_date: "",
      employeeid: "",
      categoryid: [],
      weekday: "",
      status: "",
    });

    setSelectedScheduleOfTuesday({
      schedule_date: "",
      employeeid: "",
      categoryid: [],
      weekday: "",
      status: "",
    });

    setSelectedMenuOfWednesday({
      schedule_date: "",
      employeeid: "",
      categoryid: [],
      weekday: "",
      status: "",
    });

    setSelectedMenuOfThursday({
      schedule_date: "",
      employeeid: "",
      categoryid: [],
      weekday: "",
      status: "",
    });

    setSelectedMenuOfFriday({
      employeeid: "",
      categoryid: [],
      weekday: "",
      status: "",
    });

    setSelectedMenuOfSaturday({
      schedule_date: "",
      employeeid: "",
      categoryid: [],
      weekday: "",
      status: "",
    });

    await makeGetRequest(
      "employeeschedular/getselectedschedular/" +
        empid +
        "/'" +
        startDate +
        "'/'" +
        endDate +
        "'"
    )
      .then((response) => {
        if (response.data.data) {
          let temp = response.data.data.rows;

          if (temp.length > 0) {
            for (var i = 0; i < temp.length; i++) {
              if (temp[i].weekday == "Monday") {
                setSelectedMenuOfMonday(temp[i]);
                setUpload(!upload);
              }
              if (temp[i].weekday == "Tuesday") {
                setSelectedScheduleOfTuesday(temp[i]);
                setUpload(!upload);
              }
              if (temp[i].weekday == "Wednesday") {
                setSelectedMenuOfWednesday(temp[i]);
                setUpload(!upload);
              }
              if (temp[i].weekday == "Thursday") {
                setSelectedMenuOfThursday(temp[i]);
                setUpload(!upload);
              }
              if (temp[i].weekday == "Friday") {
                setSelectedMenuOfFriday(temp[i]);
                setUpload(!upload);
              }
              if (temp[i].weekday == "Saturday") {
                setSelectedMenuOfSaturday(temp[i]);
                setUpload(!upload);
              }
              if (temp[i].weekday == "Sunday") {
                setSelectedMenuOfSunday(temp[i]);
                setUpload(!upload);
              }
            }
          } else {
            setSelectedMenuOfMonday({
              schedule_date: "",
              employeeid: "",
              categoryid: [],
              weekday: "",
              status: "",
            });
            setSelectedScheduleOfTuesday({
              schedule_date: "",
              employeeid: "",
              categoryid: [],
              weekday: "",
              status: "",
            });
            setSelectedMenuOfWednesday({
              schedule_date: "",
              employeeid: "",
              categoryid: [],
              weekday: "",
              status: "",
            });
            setSelectedMenuOfThursday({
              schedule_date: "",
              employeeid: "",
              categoryid: [],
              weekday: "",
              status: "",
            });
            setSelectedMenuOfFriday({
              schedule_date: "",
              employeeid: "",
              categoryid: [],
              weekday: "",
              status: "",
            });
            setSelectedMenuOfSaturday({
              schedule_date: "",
              employeeid: "",
              categoryid: [],
              weekday: "",
              status: "",
            });
            setSelectedMenuOfSunday({
              schedule_date: "",
              employeeid: "",
              categoryid: [],
              weekday: "",
              status: "",
            });
          }
        }
      })
      .catch((err) => {
        setShowLoader(false);
      });
  }

  async function getAllCategoryList(searchString) {
    if (!searchString) searchString = null;

    setShowLoader(true);
    await makeGetRequest(
      "category/getcategorylist/categoryid/asc/" + searchString
    )
      .then((response) => {
        if (response.data.data.res) {
          if (response.data.data.res.length > 0) {
            setCategoryList(response.data.data.res);
            setCategoryID(response.data.data.res[0].categoryid);
            getAllTaskList(null);
          } else {
            setCategoryList([]);
            setShowLoader(false);
          }
          setUpload(!upload);
        }else setShowLoader(false);
      })
      .catch((err) => {
        setShowLoader(false);
      });
  }

  async function getAllFrequancyList(searchString) {
    if (!searchString) searchString = null;
    setShowLoader(true);
    await makeGetRequest(
      "frequancy/getfrequancylist/" +
        sortColumnName +
        "/" +
        sortBy +
        "/" +
        searchString
    )
      .then((response) => {
        if (response.data.data.res) {
          if (response.data.data.res.length > 0) {
            setFreequancyList(response.data.data.res);
          } else setFreequancyList([]);
          setUpload(!upload);
        }
        setShowLoader(false);
      })
      .catch((err) => {
        setShowLoader(false);
      });
  }

  React.useEffect(() => {
    setNav1(slider1);
  }, [slider1]);

  const isDisabledCheck = (dayValue) => {
    console.log(dayValue);
    var todayDay = moment(todaysDate).format("MM-DD-YYYY");
    return dayValue < todayDay;
  };

  React.useEffect(() => {
    console.log(moment(todaysDate).format("MM-DD-YYYY"));
    console.log(currentweekCal);
    let LEssDate =
      currentweekCal.CurrentWeek > moment(todaysDate).format("MM-DD-YYYY");
    console.log(LEssDate);
    if (categoryid == null || categoryid == "" || categoryid.length == 0) {
      getAllCategoryList(null);
      // let stringMonday = getMondays();
      // setMondaysCal(stringMonday)

      const startcDate = moment("2024-09-23");
      const endcDate = moment().endOf("year").add(1, "year");

      let stringMonday = getAllMondays(startcDate, endcDate);
      setMondaysCal(stringMonday);

      let entireweek = getEntireWeek();
      setCurrentWeekCal(entireweek);
      console.log(entireweek);

      setCurrentIndex(todayIndex);
    }
  }, []);

  return (
    <>
      <div className="wrapper">
        <div className="taskListAdmMain">
          {/* TopBar */}
          <div className="innerPageTopSecBackPatch"></div>
          <div className="innerPageTopSec">
            <div className="forBackArrow forAppDrawer">
              <AppDrawer />
            </div>
            <h1>
              <marquee>Employee Shift Scheduler</marquee>
            </h1>
            <div className="forBackArrow"></div>
          </div>
          {/* TopBarEnd */}

          <Grid container spacing={1}>
            <Grid item xs={12} md={12}>
              <div className="mySlik clickAllTile" style={{ marginTop: -17 }}>
                <div className="slider-container">
                  <ArrowCircleLeftIcon
                    className="slikArLeft"
                    onClick={(e) => handleArrowClick(e, "prev")}
                  />
                  <Slider
                    className="dateSliderOuter"
                    {...settings}
                    afterChange={(index) => setCurrentIndex(index)}
                    ref={(slider) => setSlider1(slider)}
                  >
                    {mondaysCal.map((obs, idx) => (
                      <>
                        <div
                          className="dateSliderInner"
                          onClick={(e) => SelectSliders(idx, obs.Mondaydate)}
                        >
                          <span>Mon</span>
                          <h3 className="underSlikDate">{obs.Mondaydate}</h3>
                        </div>
                      </>
                    ))}
                  </Slider>
                  <ArrowCircleRightIcon
                    className="slikArRight"
                    onClick={(e) => handleArrowClick(e, "next")}
                  />
                </div>
              </div>
              {/* <div className="dateCustom">
                <img src={calendarIm} />
                <span>Select Week Starting On</span>
                <input type="date" />
              </div> */}
            </Grid>
            <Grid item xs={12} md={12}>
              <div className="listingSearch" style={{ marginBottom: 0 }}>
                <input
                  type="text"
                  placeholder="Search"
                  onChange={(e) => getAllTaskList(e.target.value)}
                />
                <div className="sortingIcoOut">
                  <ToggleButton
                    value="check"
                    selected={selected}
                    onChange={() => {
                      handleChangeSortBy();
                    }}
                  >
                    <ImportExportIcon
                      className="visitorFilterIco"
                      aria-haspopup="true"
                    />
                  </ToggleButton>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>

        <div>
          <div className="employyeListingMain" style={{ marginTop: 10 }}>
            {taskList ? (
              <>
                {taskList.map((empObj, index) => (
                  <>
                    <div
                      className={
                        add(selectedTaskList, empObj.employee_id)
                          ? "emploTaskListInr selectedList"
                          : "emploTaskListInr"
                      }
                      id={"listID-" + index + empObj.employee_id}
                      onClick={(e) => forSelectList(index, empObj.employee_id)}
                    >
                      <div className="withCheck">
                        <VerifiedIcon />
                        <h1>{empObj.full_name} </h1>
                      </div>
                    </div>
                    <div
                      className="expandDiv"
                      id={"expandId-" + index + empObj.employee_id}
                    >
                      <div className="weeklyTaskTbl">
                        <table width="100%">
                          <thead>
                            <tr>
                              <th></th>
                              <th>M</th>
                              <th>T</th>
                              <th>W</th>
                              <th>T</th>
                              <th>F</th>
                              <th>S</th>
                              <th>S</th>
                            </tr>
                            <tr>
                              <th></th>
                              {currentweekCal.map((currentobs, i) => (
                                <>
                                  <th>{manageDate(currentobs.CurrentWeek)}</th>
                                </>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {categoryList ? (
                              <>
                                {categoryList.map((option, index) => (
                                  <>
                                    <tr>
                                      <td style={{position:'relative'}}>
                                       <Button>
                                        <input
                                          className="hideInpt"
                                          type="checkbox"

                                         // checked={isSelectAllChecked}

                                         checked={!!checkedRows[(option.categoryid)]}

                                          onChange={(e) =>
                                            handleSelectAllChange(e, index,
                                              empObj.employee_id,
                                              option.categoryid,
                                              currentweekCal)
                                            }

                                          // checked={option.selectAll}
                                          // onChange={() =>
                                          //   handleSelectAllChange(
                                          //     option.categoryid
                                          //   )
                                          // }
                                        />

                                        <b className="omcCheck">
                                          <Checkbox {...label} defaultChecked />{" "}
                                          <em className="txTrans">
                                            {option.category_name[0]}
                                            <div>
                                              {option.category_name.slice(1)}
                                            </div>
                                          </em>
                                        </b>
                                        </Button>
                                      </td>

                                      <td>
                                        <Checkbox
                                          id={
                                            `mon` +
                                            index +
                                            "" +
                                            empObj.employee_id +
                                            "" +
                                            option.categoryid
                                          }
  checked={selectedMenuOfMonday.categoryid.includes(option.categoryid)? true: false}
  onChange={(e) =>handleChangeWeekDayCheckBox(e.target.checked,
                                              index,
                                              empObj.employee_id,
                                              "Monday",
                                              option.categoryid,
                                              currentweekCal
                                            )
                                          }
                                          disabled={
                                            currentweekCal[0].CurrentWeek <
                                            currentDate
                                          }
                                        />
                                      </td>

                                      <td>
                                        <Checkbox
                                          id={
                                            `tues` +
                                            index +
                                            "" +
                                            empObj.employee_id +
                                            "" +
                                            option.categoryid
                                          }
                                          checked={
                                            selectedScheduleOfTuesday.categoryid.includes(
                                              option.categoryid
                                            )
                                              ? true
                                              : false
                                          }
                                          onChange={(e) =>
                                            handleChangeWeekDayCheckBox(
                                              e.target.checked,
                                              index,
                                              empObj.employee_id,
                                              "Tuesday",
                                              option.categoryid,
                                              currentweekCal
                                            )
                                          }
                                          disabled={
                                            currentweekCal[1].CurrentWeek <
                                            currentDate
                                          }
                                        />
                                      </td>

                                      <td>
                                        <Checkbox
                                          id={
                                            `wed` +
                                            index +
                                            "" +
                                            empObj.employee_id +
                                            "" +
                                            option.categoryid
                                          }
                                          checked={
                                            selectedMenuOfWednesday.categoryid.includes(
                                              option.categoryid
                                            )
                                              ? true
                                              : false
                                          }
                                          onChange={(e) =>
                                            handleChangeWeekDayCheckBox(
                                              e.target.checked,
                                              index,
                                              empObj.employee_id,
                                              "Wednesday",
                                              option.categoryid,
                                              currentweekCal
                                            )
                                          }
                                          disabled={
                                            currentweekCal[2].CurrentWeek <
                                            currentDate
                                          }
                                        />
                                      </td>

                                      <td>
                                        <Checkbox
                                          id={
                                            `thur` +
                                            index +
                                            "" +
                                            empObj.employee_id +
                                            "" +
                                            option.categoryid
                                          }
                                          checked={
                                            selectedMenuOfThursday.categoryid.includes(
                                              option.categoryid
                                            )
                                              ? true
                                              : false
                                          }
                                          onChange={(e) =>
                                            handleChangeWeekDayCheckBox(
                                              e.target.checked,
                                              index,
                                              empObj.employee_id,
                                              "Thursday",
                                              option.categoryid,
                                              currentweekCal
                                            )
                                          }
                                          disabled={
                                            currentweekCal[3].CurrentWeek <
                                            currentDate
                                          }
                                        />
                                      </td>

                                      <td>
                                        <Checkbox
                                          id={
                                            `fri` +
                                            index +
                                            "" +
                                            empObj.employee_id +
                                            "" +
                                            option.categoryid
                                          }
                                          checked={
                                            selectedMenuOfFriday.categoryid.includes(
                                              option.categoryid
                                            )
                                              ? true
                                              : false
                                          }
                                          onChange={(e) =>
                                            handleChangeWeekDayCheckBox(
                                              e.target.checked,
                                              index,
                                              empObj.employee_id,
                                              "Friday",
                                              option.categoryid,
                                              currentweekCal
                                            )
                                          }
                                          disabled={
                                            currentweekCal[4].CurrentWeek <
                                            currentDate
                                          }
                                        />
                                      </td>

                                      <td>
                                        <Checkbox
                                          id={
                                            `sat` +
                                            index +
                                            "" +
                                            empObj.employee_id +
                                            "" +
                                            option.categoryid
                                          }
                                          checked={
                                            selectedMenuOfSaturday.categoryid.includes(
                                              option.categoryid
                                            )
                                              ? true
                                              : false
                                          }
                                          onChange={(e) =>
                                            handleChangeWeekDayCheckBox(
                                              e.target.checked,
                                              index,
                                              empObj.employee_id,
                                              "Saturday",
                                              option.categoryid,
                                              currentweekCal
                                            )
                                          }
                                          disabled={
                                            currentweekCal[5].CurrentWeek <
                                            currentDate
                                          }
                                        />
                                      </td>

                                      <td>
                                        <Checkbox
                                          id={
                                            `sunday` +
                                            index +
                                            "" +
                                            empObj.employee_id +
                                            "" +
                                            option.categoryid
                                          }
                                          checked={
                                            selectedMenuOfSunday.categoryid.includes(
                                              option.categoryid
                                            )
                                              ? true
                                              : false
                                          }
                                          onChange={(e) =>
                                            handleChangeWeekDayCheckBox(
                                              e.target.checked,
                                              index,
                                              empObj.employee_id,
                                              "Sunday",
                                              option.categoryid,
                                              currentweekCal
                                            )
                                          }
                                          disabled={
                                            currentweekCal[6].CurrentWeek <
                                            currentDate
                                          }
                                        />
                                      </td>
                                    </tr>
                                  </>
                                ))}
                              </>
                            ) : (
                              <></>
                            )}
                          </tbody>
                        </table>
                      </div>
                      <span
                        className="udateSheBt newSheBt"
                        onClick={(e) =>
                          updateSchedular(e, index, empObj.employee_id)
                        }
                      >
                        Update Shift
                      </span>
                    </div>
                  </>
                ))}
              </>
            ) : (
              <></>
            )}
          </div>
        </div>

        {/*   <div className="weeklyTaskMainOter">
        <div className="weeklyTaskMain">
          <div className="weeklyTaskHead">
            <div className="weeklyTaskPic">
              <img src={profilePic} />
            </div>
            <h1>
              Annette Black <span>93126</span>
            </h1>
          </div>
          <div className="weeklyTaskTbl">
            <table width="100%">
              <thead>
                <tr>
                  <th></th>
                  <th>M</th>
                  <th>T</th>
                  <th>W</th>
                  <th>T</th>
                  <th>F</th>
                  <th>S</th>
                  <th>S</th>
                </tr>
                <tr>
                  <th></th>
                  <th>2</th>
                  <th>3</th>
                  <th>4</th>
                  <th>5</th>
                  <th>6</th>
                  <th>7</th>
                  <th>8</th>
                </tr>
              </thead>
              <tbody>
                {rows.map((row) => (
                  <tr key={row.id}>
                    <td>
                      {row.id == 1 ? (
                        <>
                          <input
                            className="hideInpt"
                            type="checkbox"
                            checked={row.selectAll}
                            onChange={() => handleSelectAllChange(row.id)}
                          />
                          <b style={{ color: "#990000" }} className="omcTx">
                            O
                          </b>
                        </>
                      ) : row.id == 2 ? (
                        <>
                          <input
                            className="hideInpt"
                            type="checkbox"
                            checked={row.selectAll}
                            onChange={() => handleSelectAllChange(row.id)}
                          />
                          <b style={{ color: "#990000" }} className="omcTx">
                            M
                          </b>
                        </>
                      ) : row.id == 3 ? (
                        <>
                          <input
                            className="hideInpt"
                            type="checkbox"
                            checked={row.selectAll}
                            onChange={() => handleSelectAllChange(row.id)}
                          />
                          <b style={{ color: "#990000" }} className="omcTx">
                            C
                          </b>
                        </>
                      ) : (
                        <></>
                      )}
                    </td>
                    {row.checkboxes.map((checked, index) => (
                      <td key={index}>
                        <Checkbox
                          {...label}
                          defaultChecked
                          checked={checked}
                          onChange={() => handleCheckboxChange(row.id, index)}
                        />
                      </td>
                    ))}
                  </tr>
                ))}

              </tbody>
            </table>
          </div>
        </div>

        <div className="weeklyTaskMain">
          <div className="weeklyTaskHead">
            <div className="weeklyTaskPic">
              <img src={profilePic} />
            </div>
            <h1>
              Annette Black <span>93126</span>
            </h1>
          </div>
          <div className="weeklyTaskTbl">
            <table width="100%">
              <thead>
                <tr>
                  <th></th>
                  <th>M</th>
                  <th>T</th>
                  <th>W</th>
                  <th>T</th>
                  <th>F</th>
                  <th>S</th>
                  <th>S</th>
                </tr>
                <tr>
                  <th></th>
                  <th>9</th>
                  <th>10</th>
                  <th>11</th>
                  <th>12</th>
                  <th>13</th>
                  <th>14</th>
                  <th>15</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <b style={{ color: "#990000" }}>O</b>
                  </td>
                  <td>
                    <Checkbox {...label} defaultChecked />
                  </td>
                  <td>
                    <Checkbox {...label} />
                  </td>
                  <td>
                    <Checkbox {...label} />
                  </td>
                  <td>
                    <Checkbox {...label} />
                  </td>
                  <td>
                    <Checkbox {...label} />
                  </td>
                  <td>
                    <Checkbox {...label} />
                  </td>
                  <td>
                    <Checkbox {...label} />
                  </td>
                </tr>

                <tr>
                  <td>
                    <b style={{ color: "#990000" }}>M</b>
                  </td>
                  <td>
                    <Checkbox {...label} />
                  </td>
                  <td>
                    <Checkbox {...label} />
                  </td>
                  <td>
                    <Checkbox {...label} defaultChecked />
                  </td>
                  <td>
                    <Checkbox {...label} />
                  </td>
                  <td>
                    <Checkbox {...label} defaultChecked />
                  </td>
                  <td>
                    <Checkbox {...label} />
                  </td>
                  <td>
                    <Checkbox {...label} />
                  </td>
                </tr>

                <tr>
                  <td>
                    <b style={{ color: "#990000" }}>C</b>
                  </td>
                  <td>
                    <Checkbox {...label} defaultChecked />
                  </td>
                  <td>
                    <Checkbox {...label} />
                  </td>
                  <td>
                    <Checkbox {...label} defaultChecked />
                  </td>
                  <td>
                    <Checkbox {...label} />
                  </td>
                  <td>
                    <Checkbox {...label} />
                  </td>
                  <td>
                    <Checkbox {...label} />
                  </td>
                  <td>
                    <Checkbox {...label} />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div> */}

        {/* <div className="upperBottomBt">
        <Button className="logginBt">Schedule Tasks</Button>
      </div> */}
      </div>
      <AdminBottomNav />

      {/* BeforAfterImage */}
      <div className="">
        <React.Fragment>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="myAppModalMain forBeforAfterMod"
          >
            <DialogTitle id="alert-dialog-title">
              <h1 className="modalHeading">Before & After Pictures</h1>
              <IconButton className="modalCloseIco" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <div className="modalBodyCont">
                  <div className="beforAftMain">
                    <div className="beforSt">
                      <h1 className="befAftHeading">Before</h1>
                      <div className="befAftImgDiv">
                        <img src={afterBef1} />
                      </div>
                    </div>
                    <div className="afterSt">
                      <h1 className="befAftHeading">After</h1>
                      <div className="befAftImgDiv">
                        <img src={afterBef2} />
                      </div>
                    </div>
                  </div>
                </div>
              </DialogContentText>
            </DialogContent>
          </Dialog>
        </React.Fragment>
      </div>
      {showLoader ? (
        <div className="loaderNew">
          <div className="loader"></div>
        </div>
      ) : (
        <></>
      )}
    </>
  );

  // function NextArrow(props) {

  //    const datesArrowArrayVal = getArrowDatesArray(startDate, endDate)
  //    console.log("Next Arrows==>"+JSON.stringify(datesArrowArrayVal))
  //    const selectedDate = datesArrowArrayVal[props.currentSlide];
  //    console.log('Selected date:', selectedDate);
  //    if(startDate!=selectedDate)
  //   {
  //   setStartDate(selectedDate)
  //   const date = moment(selectedDate);
  //   const dayName = date.format('dddd');

  //   var curr = getMonday(new Date(date))
  //   //please check this function again
  //   let first = curr.getDate() - curr.getDay() + 2
  //   let day = new Date(curr.setDate(first)).toISOString().slice(0, 10)
  //   let lastdays = endOfWeek(curr)

  //   setStartDate(day)
  //   setEndDate(lastdays)
  //   getSelectSliderCount(day,lastdays)
  //   getSelectedWeek(day)

  //   }
  //   return (
  //     <button  class="slick-arrow slick-next" onClick={props.onClick}> Next</button>
  //   );

  // }
}

export default WeeklyTasks;

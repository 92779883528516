import * as React from "react";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import { useLocation, useNavigate } from "react-router-dom";

import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";

function UserBottom() {
  const navigate = useNavigate();
  const location = useLocation();

  const [value, setValue] = React.useState("recents");

  const handleChange = (event, newValue) => {
    setValue(newValue);
    switch (newValue) {
      case 0:
        navigate("/EmployeeTaskList");
        break;
      case 1:
        navigate("/MyAvailability");
        break;
    }
  };

  React.useEffect(() => {
    switch (location.pathname) {
      case "/EmployeeTaskList":
        setValue(0);
        break;
      case "/MyAvailability":
        setValue(1);
        break;
        setValue(4);
        break;
    }
  }, []);
  return (
    <>
      <div className="adminBottomNavMain">
        <BottomNavigation value={value} onChange={handleChange}>
          <BottomNavigationAction
            label=""
            value={0}
            icon={<FormatListBulletedIcon />}
            //onClick={() => navigate("/ReportAdmin")}
          />
          <BottomNavigationAction
            label=""
            value={1}
            icon={<EventAvailableIcon />}
            //onClick={() => navigate("/EmployeeListing")}
          />
        </BottomNavigation>
      </div>
      <div className="adminBottomNavPatch"></div>
    </>
  );
}

export default UserBottom;

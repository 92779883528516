import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import profilePic from "../../../assets/images/avtarPic.png";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import AdminBottomNav from "../AdminBottomNav/AdminBottomNav";
import SortIcon from "@mui/icons-material/Sort";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import IconButton from "@mui/material/IconButton";
import swal from "sweetalert"; 
import { makeGetRequest, makePostRequest } from "../../../utils/utils";
import ToggleButton from "@mui/material/ToggleButton";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import AppDrawer from "../../AppDrawer";

function EmployeeListing() {
  const navigate = useNavigate();
  
  const swipeNSelection = (event) => {
    forSelectList(event);
    resetSwipeDirection(event);
  };
  const forSelectList = () => {
    // var element = document.getElementById("listID");
    // element.classList.toggle("selectedList");
  };

  // For Swipe Left Right
  const [swipeDirection, setSwipeDirection] = useState(null);
  const startXRef = useRef(null);
  const threshold = 50;

  const handleTouchStart = (e) => {
    startXRef.current = e.touches[0].clientX;
  };

  const handleTouchMove = (e, index) => {
    const currentX = e.touches[0].clientX;
    const distance = currentX - startXRef.current;

    if (Math.abs(distance) > threshold) {
      if (distance > 0) {
            var element = document.getElementById("bugfree-"+tempSlide);
            if (element != null) 
            {
              element.classList.remove("swiped-right");
              element.classList.remove("swiped-left");
            }

            var elementnew = document.getElementById("bugfree-"+index);
            elementnew.classList.toggle("swiped-right");
            setTempSlide(index)
        //setSwipeDirection("right");
      } else {
        //setSwipeDirection("left");
        var element = document.getElementById("bugfree-"+tempSlide);
            if (element != null) 
            {
              element.classList.remove("swiped-left");
              element.classList.remove("swiped-right");
              
            }

            var elementnew = document.getElementById("bugfree-"+index);
            elementnew.classList.toggle("swiped-left");
            setTempSlide(index)
      }
    }
  };

  const handleTouchEnd = () => {
    var element = document.getElementById("bugfree-"+tempSlide);
    if (element != null) 
    {
      element.classList.remove("swiped-right");
      element.classList.remove("swiped-left");
    }
  };

  const resetSwipeDirection = () => {
    setSwipeDirection(null);
  };
  // Swipe Left Right End

  const [showLoader, setShowLoader] = React.useState(false);
  const [employeeList, setEmployeeList] = React.useState([]);
  const [upload, setUpload] = React.useState(false);
  const [sortBy, setSortBy] = React.useState("desc");
  const [pageNo, setPageNo] = React.useState(1);
  const [sortColumnName, setSortColumnName] = React.useState("created_at");
  const [selected, setSelected] = React.useState(false);
  const [offsetvalue, setoffsetvalue] = React.useState(0);
  const [itemsPerPage] = React.useState(1000);
  const [totalItems, setTotalItems] = React.useState(0);
  const [searchText, setSearchText] = useState("")
  const [tempSlide, setTempSlide] = React.useState("");
  async function getAllEmployeeList(searchString) {

    if (!searchString) { searchString = null; setSearchText(null)}else{ setSearchText(searchString)}
    setShowLoader(true);
    await makeGetRequest("employee/employeesearch/"+localStorage.getItem('companyId')+"/"+sortColumnName+"/" +sortBy +"/" +searchString +"/" +offsetvalue +"/" +itemsPerPage)
      .then((response) => {
        if (response.data.data.res) {
          if (response.data.data.res.length > 0) {
            setEmployeeList(response.data.data.res);
            setTotalItems(response.data.data.totalCount);
            console.log(response.data.data.totalCount);
          } else setEmployeeList([]);
          setUpload(!upload);
        }
        setShowLoader(false);
      })
      .catch((err) => {
        setShowLoader(false);
      });
  }

  function editEmployee(employeeId) {
    navigate('/EditEmployeeAdmin/' + employeeId)
  }

  function handleChangeSortBy() {
    console.log("Sort")
    setSortColumnName("full_name");
    setSelected(!selected);
    if (sortBy == "desc") setSortBy("asc");
    else setSortBy("desc");
    setUpload(!upload);
    getAllEmployeeList(searchText);
  }

  const handlePageChange = (e, page) => {
    let offsetvalue = (page - 1) * itemsPerPage;
    setoffsetvalue(offsetvalue);
    setPageNo(page);
  };

  const handleChange = (event, employee_id) => {
    // setChecked(event.target.checked);
    setShowLoader(true);

    let inputdata = {
      employee_id: employee_id,
      status: event.target.checked,
    };

    makePostRequest("employee/updateemployeestatus", inputdata)
      .then((response) => {
        if (response.data.data !== undefined) {
          getAllEmployeeList(null);
        }

      })
      .catch((err) => {
        console.log(err);
        setShowLoader(false);
      });
  };

  async function deleteEmployee(emplpoyeeId) {
    const willDelete = await swal({
      title: "Are you sure?",
      text: "Do you really want to delete this record? This process cannot be undone.",
      icon: "warning",
      buttons: ["No", "Yes"],
    });

    if (willDelete) {
      var bodyFormData = {
        employee_id: emplpoyeeId,
        is_deleted: true,
      };
      await makePostRequest("employee/updateemployeestatus", bodyFormData)
        .then((response) => {
          if (response.data) {
            swal("Success", "Record deleted successfully", "success", {
              timer: 3000,
              buttons: false,
            });
            getAllEmployeeList(searchText);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  React.useEffect(() => {
    getAllEmployeeList(null);
  }, [pageNo]);

  return (
    <>
      <div className="wrapper">
        {/* TopBar */}
        <div className="innerPageTopSecBackPatch"></div>
        <div className="innerPageTopSec">
          <div className="forBackArrow">
            <AppDrawer/>
          </div>
          <h1>Employee List</h1>
          <IconButton
            className="addEmpTpBt"
            onClick={() => navigate("/AddEmployeeAdmin")}
          >
            <AddIcon />
          </IconButton>
        </div>
        {/* TopBarEnd */}

        <div className="listingSearch">
          <input type="text"
            placeholder="Search" 
            onChange={(e) => getAllEmployeeList(e.target.value)}
          />
          <div className='sortingIcoOut'>
            <ToggleButton
              value="check"
              selected={selected}
              onChange={() => {
                handleChangeSortBy();
              }}
            >
              <ImportExportIcon
                className="visitorFilterIco"
                aria-haspopup="true"
              />
            </ToggleButton>
          </div>
        </div>

        
        <div className="employyeListingMain">
        {employeeList ? (
          <>
          {employeeList.map((empObj) => (
          <div
            // className={`swipe-container ${
            //   swipeDirection === "left" ? "swiped-left" : ""
              //     } ${swipeDirection === "right" ? "swiped-right" : ""}`}
              id={'bugfree-'+empObj.employee_id}
              className={"swipe-container"}
            onTouchStart={(e)=>handleTouchStart(e, empObj.employee_id)}
            onTouchMove={(e)=>handleTouchMove(e, empObj.employee_id)}
            //onTouchEnd={(e)=>handleTouchEnd(e, empObj.employee_id)}
            onClick={handleTouchEnd}
          >
            <div className="deleEdiBtLeft">
              <IconButton onClick={() => editEmployee(empObj.employee_id)}>
                <EditIcon />
              </IconButton>
              {/* <IconButton onClick={resetSwipeDirection}>
                <RestartAltIcon />
              </IconButton> */}
            </div>
            <div
              className={ empObj.user_type==='Admin'?"employyeListingInr leftBorderAdmin":"employyeListingInr"}
              id="listID"
              onClick={swipeNSelection}
            >
              <div className="employyeLisPic">
                <Zoom>
                  {empObj.profile_pic? <img src={empObj.profile_pic} />: 
                    <img src={profilePic} />}
                </Zoom>
              </div>
              <div className="employyeLisCont">
                <h1>{empObj.full_name}</h1>
                <span>{empObj.employee_code}</span>
              </div>
              <div className="employyeLisContRight">
                <span>{empObj.contact_no}</span>
                <p>{empObj.email_id}</p>
              </div>
            </div>
            <div className="deleEdiBtRight">
              {/* <IconButton onClick={resetSwipeDirection}>
                <RestartAltIcon className="RestartAltIcon" />
              </IconButton> */}
              {empObj.user_type==='User'? (
                <>
                <IconButton onClick={()=>deleteEmployee(empObj.employee_id                         //empObj.schemaname
                )}>
                <DeleteIcon className="DeleteIcon" />
              </IconButton>
              </>
              ):null}
            </div>
          </div>
        ))}
          </>
        ) : (
          <></>
          )}
          {/* <div className="employyeListingInr">
            <div className="employyeLisPic">
              <Zoom>
                <img src={profilePic} />
              </Zoom>
            </div>
            <div className="employyeLisCont">
              <h1 onClick={() => navigate("/EditEmployeeAdmin")}>Devon Lane</h1>
              <span>66904</span>
            </div>
            <div className="employyeLisContRight">
              <span>8956456254</span>
              <p>kenzi.lawson@example.com</p>
            </div>
          </div> */}
        </div>
        <AdminBottomNav />
      </div>
      {showLoader ? (
        <div className="loaderNew">
        <div className="loader"></div>
      </div>
      ) : (
        <></>
      )}
    </>
  );
}

export default EmployeeListing;

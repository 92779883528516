import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Grid, MenuItem } from "@mui/material";
import SortIcon from "@mui/icons-material/Sort";
import calendarIm from "../../../assets/images/calendar.png";
import VerifiedIcon from "@mui/icons-material/Verified";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import FilterIcon from "@mui/icons-material/Filter";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import avtarPic from "../../../assets/images/avtarPic.png";
// For Modal
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
// End

import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import afterBef1 from "../../../assets/images/afterBef1.png";
import afterBef2 from "../../../assets/images/afterBef2.png";
import avatarPic from "../../../assets/images/avtarPic.png";
import noPic from "../../../assets/images/no-data.png";
import profilePic from "../../../assets/images/Profile-Picture.jpg";
import Zoom from "react-medium-image-zoom";
import CreateIcon from "@mui/icons-material/Create";
import swal from "sweetalert";
import unlock from "../../../assets/images/unlock.png";
import lock from "../../../assets/images/lock.png";
import moment from "moment/moment";
import ToggleButton from "@mui/material/ToggleButton";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import {
  makeGetRequest,
  makePostRequest,
  uploadFileRequest,
} from "../../../utils/utils";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LogoutIcon from "@mui/icons-material/Logout";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import LockPersonIcon from "@mui/icons-material/LockPerson";
import compLog from "../../../assets/images/demoL.png";

// For Tabs
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import UserBottom from "../UserBottom/UserBottom";

// End

// For Tabs
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
// End

const EmployeeTaskList = ({ length = 5 }) => {
  // For Tabs
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setSearchText("");
    setValue(newValue);
    //setTempSlide("");
    //getAllTaskList(null);
  };
  // End

  const navigate = useNavigate();
  const [todaysDate, setTodaysDate] = useState({ time: new Date() });
  const [UserName, setUserName] = useState();
  const [UserId, setUserId] = useState();
  const [selectedValue, setSelectedValue] = useState([]);
  const [dropDownSelect, setDropDownSelect] = useState(null);
  const [selected, setSelected] = React.useState(false);
  const [sortBy, setSortBy] = React.useState("asc");
  const [sortColumnName, setSortColumnName] = React.useState("task_name");
  const [showLoader, setShowLoader] = React.useState(false);
  const [upload, setUpload] = React.useState(false);
  const [startDate, setStartDate] = React.useState(
    moment(todaysDate.time).format("YYYY-MM-DD")
  );
  const [categoryList, setCategoryList] = React.useState([]);
  const [employeeTaskList, setEmployeeTaskList] = useState([]);
  const [completeCount, setCompleteCount] = useState(0);
  const [completePercentage, setCompletePercentage] = useState(0);
  const [weekDay, setWeekDay] = useState("");
  const [loginErrMsg, setLoginErrMsg] = React.useState("");
  const [selectedTaskList, setSelectedTaskList] = React.useState();
  const [searchText, setSearchText] = useState("");
  const [completeIteration, setCompleteIteration] = useState(0);
  const [companypic, setCompanyPic] = useState();

  function handleChangeSortBy() {
    setSortColumnName("task_name");
    setSelected(!selected);
    if (sortBy == "desc") setSortBy("asc");
    else setSortBy("desc");
    setUpload(!upload);
    getSerachList(searchText);
    //getEmployeeSchedule(UserId);
  }

  function forProgress() {
    let result = "";
    for (let i = 0; i < completePercentage; i + 10) {
      result = '<li class="is-complete"><span>' + i + "%</span></li>";
    }

    return result;
  }

  function add(arr, name) {
    if (arr != undefined) {
      const { length } = arr;
      const id = length + 1;
      const found = arr.some(
        (el) => el.taskid === name && el.taskstatus === "Completed"
      );
      let res = false;
      if (!found) {
        res = false;
      } else {
        res = true;
      }
      return res;
    }
  }

  //For Scroll Add Class
  const [scrolling, setScrolling] = useState(false);

  // End

  //   For Click Count
  // State to keep track of the number of clicks
  const [clickCount, setClickCount] = useState(0);

  const handleskip = async (event, fullname) => {
    swal(
      "Already Assigned",
      `This task is already assigned to ${fullname}`,
      "info",
      {
        timer: 3000,
        buttons: false,
      }
    );
  };

  // Function to handle the click event
  const handleClick = async (index, taskid, empid, taskstatus) => {
    setClickCount((prevCount) => (prevCount + 1) % 4); // Cycles through 0 to 3
    setShowLoader(true);
    let values = {};
    if (taskstatus == "Open") values.taskstatus = "Assigned";
    else if (taskstatus == "Assigned") values.taskstatus = "Completed";
    else if (taskstatus == "Completed") values.taskstatus = "Open";
    else values.taskstatus = "Open";

    values.taskid = parseInt(taskid);
    values.empid = parseInt(empid);
    let catarray = parseInt(dropDownSelect);
    values.categoryid = catarray;
    values.taskassigndatetime = moment().format("YYYY-MM-DD");

    await makeGetRequest(
      "employeeschedular/selectassigntask/" +
        parseInt(empid) +
        "/" +
        parseInt(taskid) +
        "/" +
        parseInt(dropDownSelect) +
        "/'" +
        startDate +
        "'"
    )
      .then((response) => {
        console.log(
          "new response===>" + JSON.stringify(response.data.data.rows)
        );
        if (response.data.data.rowCount > 0) {
          let result = response.data.data.rows;
          if (result[0].assignid > 0 && result[0].taskstatus != "Completed") {
            makePostRequest("employeeschedular/updateassigntask", values)
              .then((response) => {
                if (response.data) {
                  getSerachList();
                  setLoginErrMsg("");
                  setShowLoader(false);
                }
              })
              .catch((err) => {
                let errMsg = err.message;
                setShowLoader(false);
                setLoginErrMsg(errMsg);
              });
          } else {
            makeGetRequest(
              "employeeschedular/deleteassigntask/" +
                parseInt(empid) +
                "/" +
                parseInt(taskid) +
                "/" +
                parseInt(dropDownSelect) +
                "/'" +
                startDate +
                "'"
            )
              .then((response) => {
                getSerachList();
                setLoginErrMsg("");
                setShowLoader(false);
              })
              .catch((err) => {
                getSerachList();
                setLoginErrMsg("");
                setShowLoader(false);
              });
          }
        } else {
          if (values.taskstatus != "Open") {
            makePostRequest("employeeschedular/insertassigntask", values)
              .then((response) => {
                if (response.data) {
                  getSerachList();
                  setLoginErrMsg("");
                  setShowLoader(false);
                }
              })
              .catch((err) => {
                let errMsg = err.message;
                setShowLoader(false);
                setLoginErrMsg(errMsg);
              });
          } else {
            getSerachList();
            setShowLoader(false);
          }
        }
      })
      .catch((err) => {});
  };

  // const getAssignEmployeeTask = async (startDate) => {

  //   await makeGetRequest("employeeschedular/getassignedEmployeestask/'"+startDate+"'").then((response) => {
  //     console.log("selected response===>",JSON.stringify(response.data.data.rows));
  //     setSelectedTaskList(response.data.data.rows)
  //   }).catch((err) => {

  //   });

  // }

  // Determine the class based on click count

  // End
  const [employeeImage, setEmployeeImage] = useState("");
  const [employeeImageErrMsg, setEmployeeImageErrMsg] = React.useState("");
  const [localImage, setLocalImage] = useState("");
  const [employeeDetails, setEmployeeDetails] = useState([]);

  async function uploadImageOrFile(fileData) {
    setShowLoader(true);
    const formData = new FormData();
    formData.append("image", fileData);
    var fileUrl;
    await uploadFileRequest("superadmin/upload", formData).then((response) => {
      fileUrl = response.data.imagepath;
    });
    setShowLoader(false);
    return fileUrl;
  }

  async function validateImage(fileData) {
    console.log("fileData", fileData.size);
    if (fileData.name.match(/.(jpg|JPG|jpeg|JPEG|png|PNG|gif|GIF)$/i)) {
      if (fileData.size < 1069000) return true;
      else setShowLoader(false);
      return false;
    } else return false;
  }
  const [ConImg, setConImg] = useState();
  /* const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        300,
        300,
        "JPEG",
        100,
        0,
        async(uri) => {
          resolve(uri);
          //console.log(uri);
        },
        "base64"
      );
    }); */

  async function uploadImage(e) {
    setEmployeeImageErrMsg("");
    setShowLoader(true);
    const file = e.target.files[0];
    let validateRes = false;
    if (await validateImage(file)) {
      var empImg = await uploadImageOrFile(file);
      let id = localStorage.getItem("userId");
      //setEmployeeImage(empImg);
      uploadProfilepic(empImg, id);
    } else {
      setEmployeeImageErrMsg(
        "Invalid file format (JPG, PNG) \n Please Select Size Less than 1 mb"
      );
    }
    //updateImage();
    setShowLoader(false);
  }

  const uploadProfilepic = (Img, id) => {
    console.log(Img);
    console.log(id);

    const formData = {
      employee_id: parseInt(id),
      profile_pic: Img,
      contact_no: employeeDetails.contact_no,
      email_id: employeeDetails.email_id,
      company_id: employeeDetails.company_id,
      employee_code: employeeDetails.employee_code,
    };
    makePostRequest("employee/updateemployee", formData)
      .then((response) => {
        if (response.data) {
          swal("Success", "Image updated successfully", "success", {
            timer: 3000,
            buttons: false,
          });
        }
        let id = localStorage.getItem("userId");
        getEmployeeDetails(id);
      })
      .catch((err) => {
        let errMsg = err.response.data.message;
        console.log(err.response.data.message);
      });
  };

  const getEmployeeDetails = async (id) => {
    setShowLoader(true);
    await makeGetRequest("/employee/getemployee/" + parseInt(id))
      .then((response) => {
        if (response.data.data) {
          if (response.data.data.length > 0) {
            console.log(response.data.data[0]);
            setEmployeeDetails(response.data.data[0]);
          } else setEmployeeDetails([]);
          setUpload(!upload);
        }
        setShowLoader(false);
      })
      .catch((err) => {
        setShowLoader(false);
      });
  };

  const forSelectList = () => {
    var element = document.getElementById("listID");
    element.classList.toggle("selectedList");
  };

  const getSerachList = async (searchString) => {

    if (!searchString) {
      searchString = null;
      setSearchText(null);
    } else {
      setSearchText(searchString);
    }
    if (dropDownSelect > 0) {
      setShowLoader(true);
      await makeGetRequest(
        "employeeschedular/employeeassigntask/" +
          UserId +
          "/'" +
          startDate +
          "'/" +
          dropDownSelect +
          "/'" +
          weekDay +
          "'/" +
          sortColumnName +
          "/" +
          sortBy +
          "/" +
          searchString
      )
        .then((response) => {
          //console.log(response.data.data.rows);
          if (response.data.data.res.rows) {
            //console.log(response.data.data);
            //alert(response.data.data.rows[0].categories.length);
            if (response.data.data.res.rows.length > 0) {
              setEmployeeTaskList(response.data.data.res.rows);
              setCompleteCount(
                response.data.data.rescounts.rows[0].total_count
              );
              let totalTask = response.data.data.res.rowCount;
              let completedTask =
                response.data.data.rescounts.rows[0].total_count;

              let Total = (completedTask * 100) / totalTask;
              let FinalTotal = Math.round(Total);
              setCompletePercentage(FinalTotal);
              const iterations = FinalTotal / 10;
              setCompleteIteration(iterations);
              if (searchString) {
                setCompleteCount(0);
              }
              setShowLoader(false);
            } else {
              setEmployeeTaskList([]);
              setCompletePercentage(0);
              const iterations = 0;
              setCompleteIteration(iterations);
              setShowLoader(false);
            }
            setUpload(!upload);
          }
        })
        .catch((err) => {
          setShowLoader(false);
        });
    } else {
      setEmployeeTaskList([]);
    }
  };

  const changeList = (e, status) => {
    //if (!searchString) searchString = null;
    localStorage.setItem("employeetaskcatid", status);
    setDropDownSelect(status);
    console.log("Selected Value", status);

    //alert(UserId)
    if (status > 0) {
      setShowLoader(true);
      makeGetRequest(
        "employeeschedular/employeeassigntask/" +
          UserId +
          "/'" +
          startDate +
          "'/" +
          status +
          "/'" +
          weekDay +
          "'" +
          "/" +
          sortColumnName +
          "/" +
          sortBy +
          "/" +
          null
      )
        .then((response) => {
          //console.log(response.data.data.rows);
          if (response.data.data.res.rows) {
            //console.log(response.data.data);
            //alert(response.data.data.rows[0].categories.length);
            if (response.data.data.res.rows.length > 0) {
              setEmployeeTaskList(response.data.data.res.rows);
              setCompleteCount(
                response.data.data.rescounts.rows[0].total_count
              );
              let totalTask = response.data.data.res.rowCount;
              let completedTask =
                response.data.data.rescounts.rows[0].total_count;
              let Total = (completedTask * 100) / totalTask;
              let FinalTotal = Math.round(Total);
              setCompletePercentage(FinalTotal);
              const iterations = FinalTotal / 10;
              setCompleteIteration(iterations);
              setShowLoader(false);
            } else {
              setEmployeeTaskList([]);
              setCompletePercentage(0);
              const iterations = 0;
              setCompleteIteration(iterations);
              setShowLoader(false);
            }
            setUpload(!upload);
          }
        })
        .catch((err) => {
          setShowLoader(false);
        });
    } else {
      setEmployeeTaskList([]);
    }
  };

  const statusChangeFun = (status) => {
    //if (!searchString) searchString = null;
    localStorage.setItem("employeetaskcatid", status.target.value);
    setDropDownSelect(status.target.value);
    console.log("Selected Value", status.target.value);

    //alert(UserId)
    if (status.target.value > 0) {
      setShowLoader(true);
      makeGetRequest(
        "employeeschedular/employeeassigntask/" +
          UserId +
          "/'" +
          startDate +
          "'/" +
          status.target.value +
          "/'" +
          weekDay +
          "'" +
          "/" +
          sortColumnName +
          "/" +
          sortBy +
          "/" +
          null
      )
        .then((response) => {
          //console.log(response.data.data.rows);
          if (response.data.data.res.rows) {
            //console.log(response.data.data);
            //alert(response.data.data.rows[0].categories.length);
            if (response.data.data.res.rows.length > 0) {
              setEmployeeTaskList(response.data.data.res.rows);
              setCompleteCount(
                response.data.data.rescounts.rows[0].total_count
              );
              let totalTask = response.data.data.res.rowCount;
              let completedTask =
                response.data.data.rescounts.rows[0].total_count;
              let Total = (completedTask * 100) / totalTask;
              let FinalTotal = Math.round(Total);
              setCompletePercentage(FinalTotal);
              const iterations = FinalTotal / 10;
              setCompleteIteration(iterations);
              setShowLoader(false);
            } else {
              setEmployeeTaskList([]);
              setCompletePercentage(0);
              const iterations = 0;
              setCompleteIteration(iterations);
              setShowLoader(false);
            }
            setUpload(!upload);
          }
        })
        .catch((err) => {
          setShowLoader(false);
        });
    } else {
      setEmployeeTaskList([]);
    }
  };

  const getEmployeeSchedule = (id) => {
    setShowLoader(true);
    //setCategoryID(id)

    makeGetRequest(
      "employeeschedular/employeesschedular/" + id + "/'" + startDate + "'"
    )
      .then((response) => {
        console.log(response.data.data);
        if (response.data.data) {
          //alert(response.data.data.rows[0].categories.length);
          if (response.data.data.rows[0].categories.length > 0) {
            setSelectedValue(response.data.data.rows[0].categories);
            setCategoryList(response.data.data.rows[0].categories);
            setWeekDay(response.data.data.rows[0].weekday);
            localStorage.setItem("employeetaskcatid",response.data.data.rows[0].categories[0].categoryid);
            setDropDownSelect(response.data.data.rows[0].categories[0].categoryid)
            getEmployeeSchedulewithexisting(id, parseInt(response.data.data.rows[0].categories[0].categoryid));
            //setWeekDay("Wednesday")
            //setSelectedTaskList(response.data.data.res.rows)
            //setIncValue(response.data.data.totalCount)
            //setShowLoader(false);
          } else {
            //setSelectedTaskList()
            //setIncValue(0)
            setShowLoader(false);
          }
          setUpload(!upload);
        }
      })
      .catch((err) => {
        setShowLoader(false);
      });
  };

  const getEmployeeSchedulewithexisting = (id, catids) => {
    setShowLoader(true);
    makeGetRequest(
      "employeeschedular/employeesschedular/" + id + "/'" + startDate + "'"
    )
      .then((response) => {
        console.log(response.data.data);
        if (response.data.data) {
          if (response.data.data.rows[0].categories.length > 0) {
            setSelectedValue(response.data.data.rows[0].categories);
            setCategoryList(response.data.data.rows[0].categories);
            setWeekDay(response.data.data.rows[0].weekday);

            setShowLoader(true);
            makeGetRequest(
              "employeeschedular/employeeassigntask/" +
                id +
                "/'" +
                startDate +
                "'/" +
                catids +
                "/'" +
                response.data.data.rows[0].weekday +
                "'" +
                "/" +
                sortColumnName +
                "/" +
                sortBy +
                "/" +
                null
            )
              .then((response) => {
                //console.log(response.data.data.rows);
                if (response.data.data.res.rows) {
                  //console.log(response.data.data);
                  //alert(response.data.data.rows[0].categories.length);
                  if (response.data.data.res.rows.length > 0) {
                    setEmployeeTaskList(response.data.data.res.rows);
                    setCompleteCount(
                      response.data.data.rescounts.rows[0].total_count
                    );
                    let totalTask = response.data.data.res.rowCount;
                    let completedTask =
                      response.data.data.rescounts.rows[0].total_count;
                    let Total = (completedTask * 100) / totalTask;
                    let FinalTotal = Math.round(Total);
                    setCompletePercentage(FinalTotal);
                    const iterations = FinalTotal / 10;
                    setCompleteIteration(iterations);
                    setShowLoader(false);
                  } else {
                    setEmployeeTaskList([]);
                    setCompletePercentage(0);
                    const iterations = 0;
                    setCompleteIteration(iterations);
                    setShowLoader(false);
                  }
                  setUpload(!upload);
                }
              })
              .catch((err) => {
                setShowLoader(false);
              });
          } else {
            //setSelectedTaskList()
            //setIncValue(0)
            setShowLoader(false);
          }
          setUpload(!upload);
        }
      })
      .catch((err) => {
        setShowLoader(false);
      });
  };

  const updateTaskSchedular = () => {
    console.log("ImgVariable", imageUploadVariables);
    setImageUploadAfter([]);
    setImageUploadBefore([]);
    setShowLoader(false);
    /* values.taskid = parseInt(taskid);
    values.empid = parseInt(empid);
    let catarray = parseInt(dropDownSelect);
    values.categoryid = catarray;
    values.taskassigndatetime = moment().format('YYYY-MM-DD'); */
    let formData = {
      taskid: imageUploadVariables.taskid,
      empid: parseInt(imageUploadVariables.userid),
      categoryid: parseInt(dropDownSelect),
      before_img: ImageUploadBefore,
      after_img: ImageUploadAfter,
      taskassigndatetime: moment().format("YYYY-MM-DD"),
    };
    makePostRequest("employeeschedular/updateassigntask", formData)
      .then((response) => {
        if (response.data) {
          getSerachList();
          setLoginErrMsg("");
          setShowLoader(false);
          swal("Success", "Information updated successfully", "success", {
            timer: 3000,
            buttons: false,
          });
          handleClose();
          setImageUploadAfter([]);
          setImageUploadBefore([]);
        }
      })
      .catch((err) => {
        let errMsg = err.message;
        setShowLoader(false);
        setLoginErrMsg(errMsg);
      });

    /* makePostRequest("employeeschedular/updatetaskschedular", formData)
    .then((response) => {
      setShowLoader(false);
      if (response.data) {
        swal("Success", "Information updated successfully", "success", {
          timer: 3000,
          buttons: false,
        });
      }
    })
    .catch((err) => {
      setShowLoader(false);
      swal("Warning", err.response.data.message, "warning", {
        timer: 3000,
        buttons: false,
      });
    }); */
  };

  const logoutFunction = () => {
    sessionStorage.clear();
    localStorage.clear();
    navigate("/");
  };

  const [imageBeforeErrMsg, setImageBeforeErrMsg] = React.useState("");
  const [imageAfterErrMsg, setImageAfterErrMsg] = React.useState("");

  const [ImageUploadBefore, setImageUploadBefore] = React.useState([]);
  const [ImageUploadAfter, setImageUploadAfter] = React.useState([]);

  async function uploadImagesBefore(e) {
    setImageBeforeErrMsg("");
    setShowLoader(true);
    const file = e.target.files[0];

    //console.log(e.target.file[0].size);
    let validateRes = false;
    if (await validateImage(file)) {
      var logoUrl = await uploadImageOrFile(file);
      setImageUploadBefore([...ImageUploadBefore, logoUrl]);
    } else {
      setImageBeforeErrMsg(
        "Invalid file format (JPG, PNG) \n Please Select Size Less than 1 mb"
      );
    }
    setShowLoader(false);
  }

  async function uploadImagesAfter(e) {
    setImageAfterErrMsg("");
    setShowLoader(true);
    const file = e.target.files[0];

    //console.log(e.target.file[0].size);
    let validateRes = false;
    if (await validateImage(file)) {
      var logoUrl = await uploadImageOrFile(file);
      setImageUploadAfter([...ImageUploadAfter, logoUrl]);
    } else {
      setImageAfterErrMsg(
        "Invalid file format (JPG, PNG) \n Please Select Size Less than 1 mb"
      );
    }
    setShowLoader(false);
  }

  const deleteImgBefore = (index) => {
    setShowLoader(true);
    ImageUploadBefore.splice(index, 1);
    setImageUploadBefore([...ImageUploadBefore]);
    setShowLoader(false);
  };

  const deleteImgAfter = (index) => {
    setShowLoader(true);
    ImageUploadAfter.splice(index, 1);
    setImageUploadAfter([...ImageUploadAfter]);
    setShowLoader(false);
  };

  // For Modal
  const [open, setOpen] = React.useState(false);

  const [imageUploadVariables, setImageUploadVariables] = useState({
    taskid: "",
    userid: "",
  });
  const handleClickOpen = (taskid, userId, taskObj) => {
    console.log("TaskId", taskid, "Userid", userId);
    console.log(taskObj);
    if (taskObj.assigntask != undefined) {
      if (taskObj.assigntask[0].empid == userId) {
        if (taskObj.assigntask[0].before_img != null)
          setImageUploadBefore([...taskObj.assigntask[0].before_img]);
        else setImageUploadBefore([]);
        if (taskObj.assigntask[0].after_img != null)
          setImageUploadAfter([...taskObj.assigntask[0].after_img]);
        else setImageUploadAfter([]);
        setImageUploadVariables({
          taskid: taskid,
          userid: userId,
        });
        setOpen(true);
      } else {
        swal(
          "This Task is Already Assigned to Someone else ",
          "Warning",
          "warning",
          {
            timer: 3000,
            buttons: false,
          }
        );
        setOpen(false);
      }
    } else {
      swal("Please assign Task First", "Warning", "warning", {
        timer: 3000,
        buttons: false,
      });
      setOpen(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setImageUploadVariables({
      taskid: "",
      userid: "",
    });
    setImageUploadAfter([]);
    setImageUploadBefore([]);
  };
  // End

  useEffect(() => {
    let catsetids = localStorage.getItem("employeetaskcatid");
    setDropDownSelect(catsetids);
    if (parseInt(catsetids) > 0) {
      setUserId(localStorage.getItem("userId"));
      let id = localStorage.getItem("userId");
      getEmployeeSchedulewithexisting(id, parseInt(catsetids));
      setInterval(() => setTodaysDate({ time: new Date() }), 1000);
      setUserName(localStorage.getItem("UserName"));
      setLocalImage(localStorage.getItem("adminProfilePic"));
    } else {
      setUserId(localStorage.getItem("userId"));
      let id = localStorage.getItem("userId");

      getEmployeeSchedule(id);
      //alert(moment(todaysDate.time).format("YYYY-MM-DD"));
      setInterval(() => setTodaysDate({ time: new Date() }), 1000);
      setUserName(localStorage.getItem("UserName"));
      setLocalImage(localStorage.getItem("adminProfilePic"));
    }
    let id = localStorage.getItem("userId");
    setCompanyPic(localStorage.getItem("companyLogo"));
    getEmployeeDetails(id);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        // Adjust this value based on your needs
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div className="adminLoginMain employeeTaskListMain">
        <div className={`adminLoginTop ${scrolling ? "scrolled" : ""}`}>
          <div className="emploProfileDiv">
            <div className="emploOute">
              <div className="emploProfilePic">
                <Zoom>
                  {employeeDetails.profile_pic ? (
                    <img src={employeeDetails.profile_pic} />
                  ) : (
                    <img src={avatarPic} />
                  )}
                </Zoom>
              </div>
              <div className="emploProfileTx">
                <h1>
                  {employeeDetails.full_name}
                  <div className="picUplod">
                    <CreateIcon />
                    <input type="file" onChange={(e) => uploadImage(e)} />
                  </div>
                </h1>
                <p>{employeeDetails.employee_code}</p>
              </div>
            </div>
            {/* <AccountCircleIcon sx={{ color: 'white', fontSize: 40 }} /> */}
            {employeeDetails.user_type == "Admin" ? (
              <AdminPanelSettingsIcon
                sx={{ color: "white", fontSize: 40 }}
                onClick={() => navigate("/ReportAdmin")}
              />
            ) : (
              <LogoutIcon
                sx={{ color: "white", fontSize: 30 }}
                onClick={logoutFunction}
              />
            )}
          </div>

          <p style={{ fontSize: 14, color: "white" }}>{employeeImageErrMsg}</p>
          <div className="emploTsCompLogMain">
            <div className="alCompLogs">
              {companypic ? <img src={companypic} /> : <img src={avtarPic} />}
            </div>
            <div className="dayTimeDateSt">
              <span>{moment(todaysDate.time).format("dddd")}</span>
              <b>
                {moment(todaysDate.time).format("L")}{" "}
                {moment(todaysDate.time).format("LTS")}
              </b>
            </div>
          </div>
        </div>
        <div className="adminLoginBody">
          <Grid container spacing={1}>
            <Grid item xs={12} md={12}>
              <div className="tabsMainD emploPrifileTbs">
                <Box sx={{ width: "100%" }}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    {/* <Tabs
                      value={value}
                      onChange={handleChange}
                      aria-label="basic tabs example"
                      variant="scrollable"
                      scrollButtons="auto"
                    >
                      <Tab label="Item One" {...a11yProps(0)} />
                      <Tab label="Item Two" {...a11yProps(1)} />
                      <Tab label="Item Three" {...a11yProps(2)} />
                    </Tabs> */}

                    {categoryList ? (
                      <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="basic tabs example"
                        variant="scrollable"
                        scrollButtons="auto"
                      >
                        {categoryList.map((catObj, i) => (
                          <Tab
                            label={catObj.category_name}
                            //onClick={(e) => changeList(e, catObj.categoryid)}
                            onClick={(e) => changeList(e, catObj.categoryid)}
                          />
                        ))}
                      </Tabs>
                    ) : (
                      <></>
                    )}
                  </Box>

                  {categoryList.map((catObj, i) => (
                    <CustomTabPanel value={value} index={i}>
                      <div className="tabInrContDiv">
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={12}>
                            <div
                              className="listingSearch"
                              style={{ marginBottom: 0 }}
                            >
                              <input
                                type="text"
                                placeholder="Search"
                                onChange={(e) => getSerachList(e.target.value)}
                              />
                              <div className="sortingIcoOut">
                                <ToggleButton
                                  value="check"
                                  selected={selected}
                                  onChange={() => {
                                    handleChangeSortBy();
                                  }}
                                >
                                  <ImportExportIcon
                                    className="visitorFilterIco"
                                    aria-haspopup="true"
                                  />
                                </ToggleButton>
                              </div>
                            </div>
                          </Grid>
                          <Grid item xs={12} md={12}>
                            {completeCount > 0 ? (
                              <Grid item xs={12} md={12}>
                                <div className="progBarMain">
                                  <div className="progBarInr">
                                    <section>
                                      {completePercentage > 0 ? (
                                        <ol class="progress-bar">
                                          {Array.from({ length: 10 }, (_, i) =>
                                            completePercentage >=
                                              10 * (i + 1) &&
                                            completePercentage >=
                                              10 * (i + 2) ? (
                                              <li
                                                key={i}
                                                class="is-complete"
                                              ></li>
                                            ) : completePercentage >=
                                                10 * (i + 1) &&
                                              completePercentage <=
                                                10 * (i + 2) ? (
                                              <li key={i} class="is-active">
                                                {completePercentage}%
                                              </li>
                                            ) : (
                                              <li key={i}></li>
                                            )
                                          )}
                                        </ol>
                                      ) : null}

                                      {/* <ol class="progress-bar">
                            <li class="is-complete">
                              <span>10%</span>
                            </li>
                            <li class="is-complete">
                              <span>20%</span>
                            </li>
                            <li class="is-complete">
                              <span>30%</span>
                            </li>
                            <li class="is-complete">
                              <span>40%</span>
                            </li>
                            <li class="is-complete">
                              <span>50%</span>
                            </li>
                            <li class="is-complete">
                              <span>60%</span>
                            </li>
                            <li class="is-active">
                              <span>70%</span>
                            </li>
                            <li>
                              <span>80%</span>
                            </li>
                            <li>
                              <span>90%</span>
                            </li>
                            <li>
                              <span>100%</span>
                            </li>
                          </ol> */}
                                    </section>
                                  </div>
                                </div>
                              </Grid>
                            ) : null}
                          </Grid>

                          <Grid item xs={12} md={12}>
                            {/* {JSON.stringify(employeeTaskList)} */}
                            {employeeTaskList.length > 0 ? (
                              <div className="employyeListingMain">
                                {employeeTaskList.map((takObj, index) => (
                                  <div>
                                    <div
                                      className="emploTaskListInr"
                                      // id="listID"
                                      // onClick={forSelectList}
                                    >
                                      <div className="withCheck">
                                        <div
                                          onClick={
                                            takObj.assigntask != null &&
                                            takObj.assigntask[0].empid == UserId
                                              ? (e) =>
                                                  handleClick(
                                                    index,
                                                    takObj.taskid,
                                                    UserId,
                                                    takObj.assigntask[0]
                                                      .taskstatus
                                                  )
                                              : takObj.assigntask != null &&
                                                takObj.assigntask[0].empid !=
                                                  UserId
                                              ? (e) =>
                                                  handleskip(
                                                    e,
                                                    takObj.assigntask[0]
                                                      .full_name
                                                  )
                                              : (e) =>
                                                  handleClick(
                                                    index,
                                                    takObj.taskid,
                                                    UserId,
                                                    "Open"
                                                  )
                                          }
                                          id={
                                            "listID-" +
                                            index +
                                            takObj.taskid +
                                            UserId
                                          }
                                          className={
                                            takObj.assigntask != null
                                              ? takObj.assigntask[0]
                                                  .taskstatus == "Assigned"
                                                ? "class2"
                                                : "class1"
                                              : ""
                                          }
                                        >
                                          <img
                                            src={unlock}
                                            className="forClass1"
                                          />
                                          <VerifiedIcon className="forClass2" />
                                          <LockPersonIcon className="forClass3" />
                                        </div>

                                        <div>
                                          <h1
                                            onClick={() =>
                                              navigate("/TaskDetails", {
                                                state: { Id: takObj.taskid },
                                              })
                                            }
                                          >
                                            {takObj.task_name}
                                            {/* <span></span> */}
                                          </h1>
                                          {takObj.assigntask != null ? (
                                            <em className="byWicTx">
                                              By{" "}
                                              {takObj.assigntask != null
                                                ? takObj.assigntask[0].full_name
                                                : null}
                                              ,{" "}
                                              {moment(
                                                takObj.assigntask[0].created_at
                                              ).format("LTS")}
                                            </em>
                                          ) : (
                                            <></>
                                          )}
                                        </div>
                                      </div>
                                      {takObj.is_beforeafter ? (
                                        <div className="taskImUpload">
                                          {/* <input type="file" /> */}
                                          <CameraAltOutlinedIcon
                                            onClick={() =>
                                              handleClickOpen(
                                                takObj.taskid,
                                                UserId,
                                                takObj
                                              )
                                            }
                                          />
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                ))}
                              </div>
                            ) : (
                              <>
                                <div className="dataNoF">
                                  <img src={noPic} />
                                  <p>Data Not Found</p>
                                </div>
                              </>
                            )}
                          </Grid>
                        </Grid>
                      </div>
                    </CustomTabPanel>
                  ))}
                </Box>
              </div>
            </Grid>
            {/* <Grid item xs={12} md={12}>
              <div className="dateCustom">
                <img src={calendarIm} />
                <span>Select Week Starting On</span>
                <select value={dropDownSelect} onChange={statusChangeFun}>
                  <option value="">Select Task Category</option>
                  {selectedValue.map((catObj) => (
                    <option value={catObj.categoryid}>
                      {catObj.category_name}
                    </option>
                  ))}
                  <option value={"shiftOpening"}>Shift Opening</option>
                  <option value={"daily"}>Daily</option>
                  <option value={"closing"}>Closing</option>
                </select>
              </div>
            </Grid> */}

            {/* <div>
              <button onClick={handleClick}>Click Me</button>
              <div className={getClassName()}>
                This div's class changes on click.
              </div>
            </div> */}
          </Grid>
        </div>
      </div>

      <UserBottom />

      {/* BeforAfterImage */}
      <div className="">
        <React.Fragment>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="myAppModalMain forBeforAfterMod"
          >
            <DialogTitle id="alert-dialog-title">
              <h1 className="modalHeading">Before & After Pictures</h1>
              <IconButton className="modalCloseIco" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <div className="modalBodyCont">
                  <div className="beforAftMain">
                    <div className="beforSt">
                      <h1 className="befAftHeading">Before</h1>
                      {ImageUploadBefore.length < 4 ? (
                        <div className="befAftImgDiv">
                          <img src={afterBef1} />
                          <Button
                            className="befAftUploadBt"
                            onChange={(e) => uploadImagesBefore(e)}
                          >
                            <input type="file" />
                            Click Picture
                          </Button>
                        </div>
                      ) : null}
                      {ImageUploadBefore.length > 0 ? (
                        <>
                          {ImageUploadBefore.map((imgObj, index) => (
                            <div className="befAftImgDiv">
                              <img src={imgObj} />
                              <div
                                className="imgClgView"
                                onClick={() => deleteImgBefore(index)}
                              >
                                <CloseIcon />
                              </div>
                            </div>
                          ))}
                        </>
                      ) : null}
                      <p className="errorSt">{imageBeforeErrMsg}</p>
                    </div>
                    <div className="afterSt">
                      <h1 className="befAftHeading">After</h1>
                      {ImageUploadAfter.length < 4 ? (
                        <div className="befAftImgDiv">
                          <img src={afterBef2} />
                          <Button
                            className="befAftUploadBt"
                            onChange={(e) => uploadImagesAfter(e)}
                          >
                            <input type="file" />
                            Click Picture
                          </Button>
                        </div>
                      ) : null}
                      {ImageUploadAfter.length > 0 ? (
                        <>
                          {ImageUploadAfter.map((imgObj, index) => (
                            <div className="befAftImgDiv">
                              <img src={imgObj} />
                              <div
                                className="imgClgView"
                                onClick={() => deleteImgAfter(index)}
                              >
                                <CloseIcon />
                              </div>
                            </div>
                          ))}
                        </>
                      ) : null}
                      <p className="errorSt">{imageAfterErrMsg}</p>
                    </div>
                  </div>
                  <Grid item xs={12} md={12}>
                    <Button className="logginBt" onClick={updateTaskSchedular}>
                      {" "}
                      Submit
                    </Button>
                  </Grid>
                  {showLoader ? (
                    <div className="loaderNew">
                      <div className="loader"></div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </DialogContentText>
            </DialogContent>
          </Dialog>
        </React.Fragment>
      </div>
      {showLoader ? (
        <div className="loaderNew">
          <div className="loader"></div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default EmployeeTaskList;

import React, { useState, useRef, useEffect } from "react";
import AddIcon from "@mui/icons-material/Add";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import SortIcon from "@mui/icons-material/Sort";
import IconButton from "@mui/material/IconButton";
import ToggleButton from "@mui/material/ToggleButton";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import profilePic from "../../../assets/images/avtarPic.png";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Zoom from "react-medium-image-zoom";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import SuperAdminBottomTab from "./SuperAdminBottomTab";
import { makeGetRequest, makePostRequest } from "../../../utils/utils";
import noPic from "../../../assets/images/no-data.png";
import swal from "sweetalert";
import LogoutIcon from "@mui/icons-material/Logout";
function AdminList() {
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const navigate = useNavigate();
  const [selected, setSelected] = React.useState(false);
  const [isActive, setIsActive] = useState(true);
  const [showLoader, setShowLoader] = React.useState(false);
  const [companyAdminList, setCompanyAdminList] = React.useState([]);
  const [sortBy, setSortBy] = React.useState("asc");
  const [upload, setUpload] = React.useState(false);
  const [sortColumnName, setSortColumnName] = React.useState("created_at");
  const [tempSlide, setTempSlide] = React.useState("");
  // For Swipe Left Right
  const [swipeDirection, setSwipeDirection] = useState(null);
  const startXRef = useRef(null);
  const threshold = 50;

  const swipeNSelection = (event, id) => {
    forSelectList(event, id);
    resetSwipeDirection(event, id);
  };
  const forSelectList = (event, id) => {
    // var element = document.getElementById("listID");
    // element.classList.toggle("selectedList");
  };

  const handleTouchStart = (e, index) => {
    startXRef.current = e.touches[0].clientX;
  };

  const handleTouchMove = (e, index) => {
    const currentX = e.touches[0].clientX;
    const distance = currentX - startXRef.current;

    if (Math.abs(distance) > threshold) {
      if (distance > 0) {
        var element = document.getElementById("bugfree-" + tempSlide);
        if (element != null) {
          element.classList.remove("swiped-right");
          element.classList.remove("swiped-left");
        }

        var elementnew = document.getElementById("bugfree-" + index);
        elementnew.classList.toggle("swiped-right");
        setTempSlide(index);
        //setSwipeDirection("right");
      } else {
        //setSwipeDirection("left");
        var element = document.getElementById("bugfree-" + tempSlide);
        if (element != null) {
          element.classList.remove("swiped-left");
          element.classList.remove("swiped-right");
        }

        var elementnew = document.getElementById("bugfree-" + index);
        elementnew.classList.toggle("swiped-left");
        setTempSlide(index);
      }
    }
  };

  const handleTouchEnd = () => {
    var element = document.getElementById("bugfree-" + tempSlide);
    if (element != null) {
      element.classList.remove("swiped-right");
      element.classList.remove("swiped-left");
    }
    // Optionally, you could reset the swipeDirection here if you want to clear it after some time
    // setSwipeDirection(null);
  };

  const resetSwipeDirection = () => {
    setSwipeDirection(null);
  };
  // Swipe Left Right End

  function handleChangeSortBy() {
    setSortColumnName("full_name");
    setSelected(!selected);
    if (sortBy == "asc") setSortBy("desc");
    else setSortBy("asc");
    setUpload(!upload);
    getAllAdminsList(null);
  }
  async function getAllAdminsList(searchString) {
    setShowLoader(true);
    if (!searchString) searchString = null;
    await makeGetRequest(
      "/superadmin/getadminlist/" +
        sortColumnName +
        "/" +
        sortBy +
        "/" +
        searchString +
        "/0/1000"
    )
      .then((response) => {
        if (response.data.data) {
          console.log(response.data.data);
          // alert(response.data.data.length)
          if (response.data.data.length > 0)
            setCompanyAdminList(response.data.data);
          else setCompanyAdminList([]);
          setUpload(!upload);
        }
        setShowLoader(false);
      })
      .catch((err) => {
        setShowLoader(false);
      });
  }
  const changeAdminStatus = (id, status) => {
    console.log("StatusActive", status);
    const formData = {
      employee_id: id,
      status: status,
    };
    makePostRequest("superadmin/updateadmin", formData)
      .then((response) => {
        if (response.data) {
          swal("Success", "Information updated successfully", "success", {
            timer: 3000,
            buttons: false,
          });
        }
        getAllAdminsList();
      })
      .catch((err) => {
        let errMsg = err.response.data.message;
        console.log(err.response.data.message);
      });
  };

  const deleteAdmin = async (id) => {
    const willDelete = await swal({
      title: "Are you sure?",
      text: "Do you really want to delete this record? This process cannot be undone.",
      icon: "warning",
      buttons: ["No", "Yes"],
    });

    if (willDelete) {
      const formData = {
        employee_id: id,
        is_deleted: true,
      };
      makePostRequest("superadmin/updateadmin", formData)
        .then((response) => {
          if (response.data) {
            swal("Success", "Information deleted successfully", "success", {
              timer: 3000,
              buttons: false,
            });
          }
          getAllAdminsList();
        })
        .catch((err) => {
          let errMsg = err.response.data.message;
          console.log(err.response.data.message);
        });
    }
  };

  const logoutFunction = () => {
    sessionStorage.clear();
    localStorage.clear();
    navigate("/");
  };

  useEffect(() => {
    getAllAdminsList(null);
  }, []);

  return (
    <div className="wrapper">
      {/* TopBar */}
      <div className="innerPageTopSecBackPatch"></div>
      <div className="innerPageTopSec">
        <div className="forBackArrow">
          {/* <LogoutIcon sx={{ color: 'black', fontSize: 30 }} onClick={logoutFunction} /> */}
        </div>
        <h1>Admin List</h1>
        <IconButton
          className="addEmpTpBt"
          onClick={() => navigate("/AddCompanyAdmin")}
        >
          <AddIcon />
        </IconButton>
      </div>
      {/* TopBarEnd */}
      <div className="listingSearch">
        <input
          type="text"
          placeholder="Search"
          onChange={(e) => getAllAdminsList(e.target.value)}
        />
        {/* <div>
        <SortIcon  onClick={() => {
            handleChangeSortBy();
        }}/>
        </div> */}
        <div className="sortingIcoOut">
          <ToggleButton
            value="check"
            selected={selected}
            onChange={() => {
              handleChangeSortBy();
            }}
          >
            <ImportExportIcon
              className="visitorFilterIco"
              aria-haspopup="true"
            />
          </ToggleButton>
        </div>
      </div>
      <div className="employyeListingMain">
        {companyAdminList.length > 0 ? (
          <>
            {companyAdminList.map((adminObj, index) => (
              <div
                id={"bugfree-" + adminObj.employee_id}
                // className={`swipe-container ${
                //   swipeDirection === "left" ? "swiped-left" : ""
                // } ${swipeDirection === "right" ? "swiped-right" : ""}`}
                className="swipe-container"
                onTouchStart={(e) => handleTouchStart(e, adminObj.employee_id)}
                onTouchMove={(e) => handleTouchMove(e, adminObj.employee_id)}
                onClick={(e) => handleTouchEnd(e, adminObj.employee_id)}
                key={index}
              >
                <div className="deleEdiBtLeft">
                  <IconButton
                    onClick={() =>
                      navigate("/EditCompanyAdmin", {
                        state: { Id: adminObj.employee_id },
                      })
                    }
                  >
                    <EditIcon />
                  </IconButton>
                  {/* <IconButton onClick={resetSwipeDirection}>
                <RestartAltIcon />
              </IconButton> */}
                </div>
                <div
                  className="employyeListingInr"
                  id="listID"
                  onClick={(e) => swipeNSelection(e, adminObj.employee_id)}
                >
                  <div className="employyeLisPic">
                    <Zoom>
                      {adminObj.profile_pic ? (
                        <img src={adminObj.profile_pic} />
                      ) : (
                        <img src={profilePic} />
                      )}
                    </Zoom>
                  </div>
                  <div className="employyeLisCont">
                    <h1>{adminObj.full_name}</h1>
                    <span>{adminObj.company_name}</span>
                  </div>
                  <div className="employyeLisContRight">
                    <span>{adminObj.contact_no}</span>
                    <p>{adminObj.email_id}</p>
                  </div>
                </div>
                <div className="deleEdiBtRight pdm">
                  <IconButton>
                    <div className="mySwitch">
                      <Switch
                        {...label}
                        checked={adminObj.status}
                        onChange={(e) =>
                          changeAdminStatus(
                            adminObj.employee_id,
                            e.target.checked
                          )
                        }
                      />
                    </div>
                  </IconButton>

                  <IconButton
                    onClick={(e) => deleteAdmin(adminObj.employee_id)}
                  >
                    <DeleteIcon className="DeleteIcon" />
                  </IconButton>
                </div>
              </div>
            ))}
          </>
        ) : (
          <div className="dataNoF">
            <img src={noPic} />
            <p>Data Not Found</p>
          </div>
        )}
      </div>
      {showLoader ? (
        <div className="loaderNew">
          <div className="loader"></div>
        </div>
      ) : (
        <></>
      )}
      <SuperAdminBottomTab />
    </div>
  );
}

export default AdminList;

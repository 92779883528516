import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Grid, Box } from "@mui/material";
import "react-medium-image-zoom/dist/styles.css";
import AdminBottomNav from "../AdminBottomNav/AdminBottomNav";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";

import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import profilePic from "../../../assets/images/Profile-Picture.jpg";
import noLogo from "../../../assets/images/noLogo.jpg";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../../FormikControl";
import { styled } from "@mui/material/styles";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

import {
  makeGetRequest,
  makePostRequest,
  uploadFileRequest,
} from "../../../utils/utils";
import SuperAdminBottomTab from "./SuperAdminBottomTab";
import swal from "sweetalert";

function AddCompany(props) {
  const navigate = useNavigate();
  const { id } = useParams();

  const [showLoader, setShowLoader] = React.useState(false);
  const [loginErrMsg, setLoginErrMsg] = React.useState("");
  const [formValues, setFormValues] = React.useState(null);
  const [TempUser, setTempUser] = useState([]);
  const [isActive, setIsActive] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);
  const [fullname, setFullname] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [emailid, setEmailid] = React.useState("");
  const [contactno, setContactno] = React.useState("");
  const [companyDropDown, setcompanyDropDown] = React.useState([
    { key: 1 },
    { key: 2 },
  ]);
  const [profileErrMsg, setProfileErrMsg] = React.useState("");
  const [companylogo, setCompanyLogo] = React.useState("");
  const [companyScaleList, setCompanyScaleList] = React.useState([
    "Small Scale Organization",
    "Mid Scale Organization",
    "Large Scale Organization",
  ]);
  const [serviceTypeList, setServiceTypeList] = React.useState([
    "Star Market",
    "Star Cafe",
    "Barber Shop",
    "Sports",
    "Gym",
  ]);

  const [selectedScale, setSelectedScale] = React.useState(companyScaleList[0]);
  const [selectedServiceTypeList, setSelectedServiceTypeList] = React.useState(
    []
  );
  const [duplicateNameErrMsg, setDuplicateNameErrMsg] = React.useState("");
  const [duplicateEmailErrMsg, setDuplicateEmailErrMsg] = React.useState("");
  const [duplicateContactErrMsg, setDuplicateContactErrMsg] =
    React.useState("");
  const [serviceTypeErrMsg, setServiceTypeErrMsg] = React.useState("");
  const [Upload, setUpload] = React.useState(false);

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const initialValues = {
    company_name: "",
    email_id: "",
    contact_no: "",
    address: "",
    owners_name: "",
    company_type: "",
    company_website: "",
    fax_number: "",
    tax_identification_number: "",
  };

  const validationSchema = Yup.object({
    company_name: Yup.string()
      .trim("White spaces not allow")
      .strict(true)
      .required("Required")
      .matches(
        /^([a-z.A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
        "Numbers/Special Characters Not Allowed!!"
      ),
    email_id: Yup.string()
      .matches(
        /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/,
        "Invalid Email Id"
      )
      .required("Required"),
    contact_no: Yup.string()
      .length(10, "Phone no should be 10 digits long")
      .matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        "Phone number is not valid"
      )
      .required("Required"),
    address: Yup.string()
      .trim("White spaces not allow")
      .strict(true)
      .required("Required"),
    owners_name: Yup.string()
      .trim("White spaces not allow")
      .strict(true)
      .required("Required")
      .matches(
        /^([a-z.A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
        "Numbers/Special Characters Not Allowed!!"
      ),
    company_type: Yup.string()
      .trim("White spaces not allow")
      .strict(true)
      .required("Required"),
    company_website: Yup.string()
      .trim("White spaces not allow")
      .strict(true)
      .required("Required").matches(
        /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[a-zA-Z0-9#]+\/?)*$/,
        "Enter a valid website URL"
      ),
    /* fax_number: Yup.string()
      .trim("White spaces not allow")
      .strict(true)
      .required("Required"),
    tax_identification_number: Yup.string()
      .trim("White spaces not allow")
      .strict(true)
      .required("Required"), */
  });

  async function uploadImageOrFile(fileData) {
    setShowLoader(true);
    const formData = new FormData();
    formData.append("image", fileData);
    var fileUrl;
    await uploadFileRequest("superadmin/upload", formData).then((response) => {
      fileUrl = response.data.imagepath;
    });
    setShowLoader(false);
    return fileUrl;
  }

  async function validateImage(fileData) {
    console.log("fileData", fileData.size);
    if (fileData.name.match(/.(jpg|JPG|jpeg|JPEG|png|PNG|gif|GIF)$/i)) {
      if (fileData.size < 1069000) return true;
       else setShowLoader(false); return false;
    }
    else return false;
  }

  async function uploadLogo(e) {
    setProfileErrMsg("");
    setShowLoader(true);
    const file = e.target.files[0];
    const fileSize = e.target.files[0].size;
    //console.log(e.target.file[0].size);
    let validateRes = false;
    if (await validateImage(file)) {
      var logoUrl = await uploadImageOrFile(file);
      setCompanyLogo(logoUrl);
    } else {
      setProfileErrMsg("Invalid file format (JPG, PNG) \n Please Select Size Less than 1 mb");
    }
    setShowLoader(false);
  }

  async function handleChangeScale(scale) {
    setSelectedScale(scale);
  }

  async function handleChangeServiceType(service_type) {
    if (selectedServiceTypeList.includes(service_type)) {
      selectedServiceTypeList.splice(
        selectedServiceTypeList.indexOf(service_type),
        1
      );
      setSelectedServiceTypeList(selectedServiceTypeList);
    } else {
      selectedServiceTypeList.push(service_type);
      setSelectedServiceTypeList(selectedServiceTypeList);
    }

    setUpload(!Upload);
  }

  const addOrUpdateCompany = (values) => {
    // values.organisation_type = selectedScale;
    // values.company_logo = companylogo;

    if (id > 0) {
      setShowLoader(true);
      console.log(values);
      // values.
      values.company_logo = companylogo;
      values.organisation_type = selectedScale;

      makePostRequest("superadmin/updatecompany", values)
        .then((response) => {
          if (response.data) {
            swal("Success", response.data.message, "success", {
              timer: 3000,
              buttons: false,
            });
            navigate("/SuperAdminDashboard");
            setShowLoader(false);
          }
        })
        .catch((err) => {
          swal("Warning", err.response.data.message, "warning", {
            timer: 3000,
            buttons: false,
          });
          setShowLoader(false);
        });
    } else {
      values.company_logo = companylogo;
      values.organisation_type = selectedScale;
      setShowLoader(true);
      makePostRequest("superadmin/insertcompany", values)
        .then((response) => {
          if (response.data) {
            // console.log(response)
            swal("Success", response.data.message, "success", {
              timer: 1000,
              buttons: false,
            });
            navigate("/SuperAdminDashboard");
            setShowLoader(false);
          }
        })
        .catch((err) => {
          swal("Warning", err.response.data.message, "warning", {
            timer: 3000,
            buttons: false,
          });
          setShowLoader(false);
        });
    }
    // } else {
    //   console.log("first")
    //   setServiceTypeErrMsg("Please select service type");
    //   setShowLoader(false);
    // }
    /* if (selectedServiceTypeList.length > 0) {
      if (isEditCompany) {
        setShowLoader(true);
        values.service_type = selectedServiceTypeList;
        makePostRequest("superadmin/updatecompany", values)
          .then((response) => {
            if (response.data) {
              // console.log(response)
              navigate("/SuperAdminDashboard");
            }
          })
          .catch((err) => {
            if (err.response.status == 409) {
              let errMsg = err.response.data.message;
              let errMsgArray = errMsg.split(" ");
              console.log(errMsgArray);
              if (errMsgArray.includes("companyname:"))
                setDuplicateNameErrMsg("Name already exist");
              else if (errMsgArray.includes("emailid:"))
                setDuplicateEmailErrMsg("Email already exist");
              else if (errMsgArray.includes("contactno:"))
                setDuplicateContactErrMsg("Already exist");
            }
            setShowLoader(false);
          });
      } else {
        setShowLoader(true);
        values.service_type = selectedServiceTypeList;
        makePostRequest("superadmin/insertcompany", values)
          .then((response) => {
            if (response.data) {
              // console.log(response)
              navigate("/SuperAdminDashboard");
            }
          })
          .catch((err) => {
            if (err.response.status == 409) {
              let errMsg = err.response.data.message;
              let errMsgArray = errMsg.split(" ");
              console.log(errMsgArray);

              if (errMsgArray.includes("companyname:"))
                setDuplicateNameErrMsg("Name already exist");
              else if (errMsgArray.includes("emailid:"))
                setDuplicateEmailErrMsg("Email already exist");
              else if (errMsgArray.includes("contactno:"))
                setDuplicateContactErrMsg("Already exist");
            }
            setShowLoader(false);
          });
      }

      setDuplicateNameErrMsg("");
      setDuplicateEmailErrMsg("");
      setDuplicateContactErrMsg("");
    } else {
      setServiceTypeErrMsg("Please select service type");
    } */
  };

  const checkAdminStatus = (status) => {
    console.log("StatusAdmin", status);
    setIsAdmin(status);
  };
  const checkActiveStatus = (status) => {
    console.log("StatusActive", status);
    setIsActive(status);
  };

  const [isEditCompany, setIsEditCompany] = React.useState(false);

  async function viewCompany() {
    setShowLoader(true);
    makeGetRequest("superadmin/getcompany/" + id)
      .then((response) => {
        if (response.data) {
          console.log(response.data.data[0]);
          setFormValues(response.data.data[0]);
          console.log(response.data.data[0]);
          setSelectedScale(response.data.data[0].organisation_type);
          setCompanyLogo(response.data.data[0].company_logo)
          setShowLoader(false);
        }
      })
      .catch((err) => {
        console.log(err);
        let errMsg = err.response.data.message;
        setShowLoader(false);
      });
  }

  useEffect(() => {
    if (id > 0) {
      viewCompany();
    }

    /* if (props.name) {
      setShowLoader(true);
      makeGetRequest("superadmin/getcompany/" + props.name)
        .then((response) => {
          if (response.data) {
            console.log(response.data.data[0]);
            //console.log(serviceTypeList);
            setFormValues(response.data.data[0]);
            setIsEditCompany(true);
            setCompanyLogo(response.data.data[0].company_logo);
            setSelectedScale(response.data.data[0].organisation_type);
            document
              .getElementById("company_name")
              .setAttribute("disabled", true);
            setDuplicateNameErrMsg("Unable to update company name");
            for (
              var i = 0;
              i < response.data.data[0].service_type.length;
              i++
            ) {
              selectedServiceTypeList.push(
                response.data.data[0].service_type[i]
              );
            }
            setSelectedServiceTypeList(selectedServiceTypeList);
            setShowLoader(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } */
  }, []);

  return (
    <div>
      <div className="wrapper">
        {/* TopBar */}
        <div className="innerPageTopSecBackPatch"></div>
        <div className="innerPageTopSec">
          <div className="forBackArrow">
            <NavigateBeforeIcon onClick={() => navigate(-1)} />
          </div>
          <h1>Add Company</h1>
          <div className="forBackArrow"></div>
        </div>
        {/* TopBarEnd */}

        <div className="addAdminEmployeeMain">
          <Formik
            initialValues={formValues || initialValues}
            validationSchema={validationSchema}
            onSubmit={addOrUpdateCompany}
            enableReinitialize
          >
            {(formik) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12} mt={5}>
                    <div className="uploadImBox">
                      <div className="logoPrevOuter">
                        <div className="logoPrev">
                          {companylogo ? (
                            <img src={companylogo} />
                          ) : (
                            <img src={noLogo} />
                          )}
                        </div>
                      </div>
                      <h1 className="">Upload Logo Here</h1>
                      <Button
                        className="uploadImBt"
                        component="label"
                        variant="contained"
                        startIcon={<CloudUploadIcon />}
                      >
                        Upload file
                        <VisuallyHiddenInput
                          type="file"
                          onChange={(e) => uploadLogo(e)}
                        />
                      </Button>
                      <span>or drag file in here</span>
                      <span className="errorSt">{profileErrMsg}</span>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <label className="myLable">Type Of Organisation</label>
                    <div className="orgnigDiv">
                      {companyScaleList.map((scaleOption) => (
                        <div
                          className={
                            selectedScale == scaleOption
                              ? "orgniInner activeOrgniInner"
                              : "orgniInner"
                          }
                          onClick={() => {
                            handleChangeScale(scaleOption);
                          }}
                        >
                          <CorporateFareIcon />
                          {scaleOption}
                        </div>
                      ))}
                      {/* <div className="orgniInner">
                        <CorporateFareIcon />
                        Mid Scale
                      </div>

                      <div className="orgniInner">
                        <CorporateFareIcon />
                        Large Scale
                      </div> */}
                    </div>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormikControl
                      control="input"
                      type="text"
                      label="Company Name"
                      placeholder="Enter Company Name"
                      labelClass="myLable"
                      name="company_name"
                      className="myInput"
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormikControl
                      control="input"
                      type="text"
                      label="Company Email"
                      placeholder="Enter Company Email"
                      labelClass="myLable"
                      name="email_id"
                      className="myInput"
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormikControl
                      control="input"
                      type="text"
                      label="Company Phone Number"
                      placeholder="Enter Company Phone Number"
                      labelClass="myLable"
                      name="contact_no"
                      className="myInput"
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormikControl
                      control="textarea"
                      type="text"
                      label="Enter Company Address"
                      placeholder="Enter Company Address"
                      labelClass="myLable"
                      name="address"
                      className="myTextArea"
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormikControl
                      control="input"
                      type="text"
                      label="Owner Name"
                      placeholder="Enter Owner Name"
                      labelClass="myLable"
                      name="owners_name"
                      className="myInput"
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormikControl
                      control="input"
                      type="text"
                      label="Type of Company"
                      placeholder="Enter Type of Company"
                      labelClass="myLable"
                      name="company_type"
                      className="myInput"
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormikControl
                      control="input"
                      type="text"
                      label="Company Website"
                      placeholder="Enter Company Website"
                      labelClass="myLable"
                      name="company_website"
                      className="myInput"
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormikControl
                      control="input"
                      type="text"
                      label="Fax Number"
                      placeholder="Enter Fax Number"
                      labelClass="myLable"
                      name="fax_number"
                      className="myInput"
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormikControl
                      control="input"
                      type="text"
                      label="Tax Identification Number"
                      placeholder="Enter Tax Number"
                      labelClass="myLable"
                      name="tax_identification_number"
                      className="myInput"
                    />
                  </Grid>

                  {/* <Grid item xs={12} md={12}>
                    <div className="mySwitch">
                      <FormControlLabel
                        control={<Switch checked={isActive} onChange={(e)=>checkActiveStatus(e.target.checked)}/>}
                        label="Active"
                      />
                    </div>
                  </Grid> */}
                  {/* <Grid item xs={12} md={12}>
                    <div className="mySwitch">
                      <FormControlLabel
                        control={<Switch checked={isAdmin} onChange={(e)=>checkAdminStatus(e.target.checked)}/>}
                        label="Is this Admin User?"
                      />
                    </div>
                  </Grid> */}

                  <Grid item xs={12} md={12} className="stickyBtBottom">
                    <Button type="submit" className="logginBt">
                      {id > 0 ? (
                        <span>Update Company</span>
                      ) : (
                        <span>Add Company</span>
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </div>

        {showLoader && (
          <div className="loaderNew">
            <div className="loader"></div>
          </div>
        )}
        <SuperAdminBottomTab />
      </div>
    </div>
  );
}

export default AddCompany;

import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Grid } from "@mui/material";
import "react-medium-image-zoom/dist/styles.css";
import AdminBottomNav from "../AdminBottomNav/AdminBottomNav";
import SortIcon from "@mui/icons-material/Sort";
import FilterIcon from "@mui/icons-material/Filter";
import VerifiedIcon from "@mui/icons-material/Verified";
import { makeGetRequest, makePostRequest } from "../../../utils/utils";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import moment from "moment/moment";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import afterBef1 from "../../../assets/images/afterBef1.png";
import afterBef2 from "../../../assets/images/afterBef2.png";
import underContruction from "../../../assets/images/under-construction.gif";
import ToggleButton from "@mui/material/ToggleButton";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import FloatButton from "../../FloatButton";
import AppDrawer from "../../AppDrawer";
import noPic from "../../../assets/images/no-data.png";
import unlock from "../../../assets/images/unlock.png";
import lock from "../../../assets/images/lock.png";
import swal from "sweetalert";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import LockPersonIcon from "@mui/icons-material/LockPerson";

// For Tabs
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
// ENd

function getDatesArray(startDate, endDate) {
  const dates = [];
  const start = moment(startDate);
  const end = moment(endDate);

  while (start.isSameOrBefore(end)) {
    dates.push({
      date: start.format("YYYY-MM-DD"),
      day: start.format("ddd"),
      formatdate: start.format("DD"),
    });
    start.add(1, "day");
  }

  return dates;
}

function ReportAdmin() {
  const navigate = useNavigate();
  const [selected, setSelected] = React.useState(false);
  const [sortColumnName, setSortColumnName] = React.useState("task_name");
  const [sortBy, setSortBy] = React.useState("asc");
  const [upload, setUpload] = React.useState(false);
  const [categoryList, setCategoryList] = React.useState([]);
  const [showLoader, setShowLoader] = React.useState(false);
  const [categoryid, setCategoryID] = React.useState([]);
  const [categoryname, setCategoryName] = React.useState([]);
  const [frequancyid, setFrequancyID] = React.useState([]);
  const [freequancyList, setFreequancyList] = React.useState([]);
  const [slider1, setSlider1] = useState(null);
  const [mondaysCal, setMondaysCal] = React.useState([]);
  const [taskid, setTaskID] = React.useState(0);
  const [tempSlide, setTempSlide] = React.useState("");
  const [todaysDate, setTodaysDate] = useState({ time: new Date() });
  const [currentweekCal, setCurrentWeekCal] = React.useState([]);
  const [startDate, setStartDate] = React.useState(moment("2024-10-01"));
  const [startADate, setStartADate] = React.useState(
    moment(todaysDate.time).format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = React.useState(
    moment().endOf("year").add(1, "year")
  );
  const [datesArray, setDatesArray] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [dropDownSelect, setDropDownSelect] = useState(null);
  const [UserId, setUserId] = useState();
  const [weekDay, setWeekDay] = useState(moment().format("dddd"));
  const [employeeTaskList, setEmployeeTaskList] = useState([]);
  const [completeCount, setCompleteCount] = useState(0);
  const [completePercentage, setCompletePercentage] = useState(0);
  const [completeIteration, setCompleteIteration] = useState(0);
  const [growthPercentage, setGrowthPercentage] = useState(10);
  const [avaialableEmployee, setAvaialableEmployee] = useState([]);
  const [availablecnt, setAvailableCnt] = useState(0);

  const initialValues = [100];
  function handleChangeSortBy() {
    setSortColumnName("task_name");
    setSelected(!selected);
    if (sortBy == "desc") setSortBy("asc");
    else setSortBy("desc");
    setUpload(!upload);
    getSerachList(searchText);
  }

  // For Modal
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (taskObj) => {
    console.log(taskObj);
    if (taskObj.assigntask == null) {
      swal("This Task is not Assigned to anyone ", "Warning", "warning", {
        timer: 3000,
        buttons: false,
      });
      setOpen(false);
    } else {
      if (
        (taskObj.assigntask != null &&
          taskObj.assigntask[0].before_img !== null) ||
        taskObj.assigntask[0].after_img !== null
      ) {
        setImageUploadBefore(taskObj.assigntask[0].before_img);
        setImageUploadAfter(taskObj.assigntask[0].after_img);
        setOpen(true);
      } else {
        swal("No Image Uploaded", "Information", "info", {
          timer: 3000,
          buttons: false,
        });
        setOpen(false);
      }
    }
  };

  const handleClose = () => {
    setOpen(false);
  };
  // End

  function getArrowDatesArray(startDate, endDate) {
    const dates = [];
    const start = moment(startDate);
    const end = moment(endDate);

    while (start.isSameOrBefore(end)) {
      dates.push(start.format("YYYY-MM-DD"));
      start.add(1, "day");
    }

    //console.log("new dates===>"+JSON.stringify(dates))

    return dates;
  }

  const datesArrayVal = getDatesArray(startDate, endDate);
  const today = moment();
  const todayIndex = datesArrayVal.findIndex((arraydate) =>
    moment(arraydate.date).isSame(today, "day")
  );

  const settings = {
    initialSlide: todayIndex,
    // className: "center",
    // centerMode: true,
    // infinite: true,
    // centerPadding: "0px",
    // slidesToShow: 3,
    // speed: 500,
    // nextArrow: <NextArrow />,
    // prevArrow: <PrevArrow />,

    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    arrows: true,
    dots: false,
    speed: 300,
    centerPadding: "20px",
    infinite: true,
    autoplaySpeed: 5000,
    //autoplay: true,
    // nextArrow: <NextArrow />,
  };

  const forSelectList = () => {
    var element = document.getElementById("listID");
    element.classList.toggle("selectedList");
  };

  function getMonday(d) {
    var date = new Date(d);
    const day = date.getDay();
    const diff = date.getDate() - day + (day === 0 ? -6 : 1);
    return new Date(d.setDate(diff));
  }

  // For Tabs
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  // End

  function endOfWeek(date) {
    var lastday = date.getDate() - (date.getDay() - 1) + 7;
    return new Date(date.setDate(lastday)).toISOString().slice(0, 10);
  }

  function getSelectedWeek(startDate) {
    let week = [];
    var newdates = new Date(startDate);
    var d = new Date(startDate);
    d.setDate(1);
    var curr = getMonday(newdates);
    var xday = curr.getDate();
    d.setDate(xday);

    for (let i = 1; i <= 7; i++) {
      let first = curr.getDate() - curr.getDay() + i;
      let day = new Date(curr.setDate(first)).toISOString().slice(0, 10);
      week.push({ CurrentWeek: day });
    }
    setCurrentWeekCal(week);
  }

  function SelectSliders(idx, obs) {
    setStartADate(obs);
    const date = moment(obs);
    const dayName = date.format("dddd");
    setWeekDay(dayName);
    slider1?.slickGoTo(idx);
    ChangeDateFun(categoryid, obs, dayName);
  }

  const changeList = (e, id, catname) => {
    setShowLoader(true);
    setCategoryID(id);
    setCategoryName(catname);
    const obsdate = moment(todaysDate.time).format("YYYY-MM-DD");
    setStartADate(moment(todaysDate.time).format("YYYY-MM-DD"));
    const date = moment(moment(todaysDate.time).format("YYYY-MM-DD"));
    const dayName = date.format("dddd");
    setWeekDay(dayName);
    ChangeDateFun(id, obsdate, dayName);
  };

  const getSerachList = async (searchString) => {
    if (!searchString) {
      searchString = null;
      setSearchText(null);
    } else {
      setSearchText(searchString);
    }
    if (dropDownSelect > 0) {
      setShowLoader(true);
      let userids = localStorage.getItem("userId");
      await makeGetRequest(
        "employeeschedular/employeereporttask/" +
          userids +
          "/'" +
          startADate +
          "'/" +
          dropDownSelect +
          "/'" +
          weekDay +
          "'" +
          "/" +
          sortColumnName +
          "/" +
          sortBy +
          "/" +
          searchString
      )
        .then((response) => {
          if (response.data.data.res.rows) {
            if (response.data.data.res.rows.length > 0) {
              setEmployeeTaskList(response.data.data.res.rows);
              //console.log("Sagar",response.data.data.res.rows)
              setCompleteCount(
                response.data.data.rescounts.rows[0].total_count
              );
              let totalTask = response.data.data.res.rowCount;
              let completedTask =
                response.data.data.rescounts.rows[0].total_count;

              let Total = (completedTask * 100) / totalTask;
              let FinalTotal = Math.round(Total);
              setCompletePercentage(FinalTotal);
              const iterations = FinalTotal / 10;
              setCompleteIteration(iterations);
              if (searchString) {
                setCompleteCount(0);
              }
              setShowLoader(false);
            } else {
              setEmployeeTaskList([]);
              setCompletePercentage(0);
              const iterations = 0;
              setCompleteIteration(iterations);
              setShowLoader(false);
            }
            setUpload(!upload);
          }
        })
        .catch((err) => {
          setShowLoader(false);
        });
    } else {
      setEmployeeTaskList([]);
    }
  };

  const [ImageUploadBefore, setImageUploadBefore] = React.useState([]);
  const [ImageUploadAfter, setImageUploadAfter] = React.useState([]);

  async function getCompletedTaskCount(catid,startdate)
  {

    await makeGetRequest("employeeschedular/selectreporttask/"+catid+"/"+startdate)
      .then((response) => {
        console.log("My available employee list==>"+JSON.stringify(response))
        if (response.data.data.rows) {
          
          if (response.data.data.rows.length > 0) 
            {
              setAvaialableEmployee(response.data.data.rows)
              setAvailableCnt(response.data.data.rowCount)
              setShowLoader(false);
            } 
            else 
            {
              setAvaialableEmployee([])
              setAvailableCnt(0)
              setShowLoader(false);
            }
          
          setUpload(!upload);
        }
        
      })
      .catch((err) => {
        setShowLoader(false);
      });


  }

  const statusChangeFun = async (catid) => {
    //if (!searchString) searchString = null;
    localStorage.setItem("categoryrsetid", catid);
    setDropDownSelect(catid);
    console.log("Selected Value", catid);

    //alert(UserId)
    if (catid > 0) {
      //setShowLoader(true);
      let userids = localStorage.getItem("userId");
      await makeGetRequest(
        "employeeschedular/employeereporttask/" +
          userids +
          "/'" +
          startADate +
          "'/" +
          catid +
          "/'" +
          weekDay +
          "'" +
          "/" +
          sortColumnName +
          "/" +
          sortBy +
          "/" +
          null
      )
        .then((response) => {
          //console.log(response.data.data.rows);
          if (response.data.data.res.rows) {
            //alert(response.data.data.rows[0].categories.length);
            if (response.data.data.res.rows.length > 0) {
              //console.log("Sagar",response.data.data.res.rows[0].assigntask[0].after_img)
              setEmployeeTaskList(response.data.data.res.rows);
              setCompleteCount(
                response.data.data.rescounts.rows[0].total_count
              );
              let totalTask = response.data.data.res.rowCount;
              let completedTask =
                response.data.data.rescounts.rows[0].total_count;
              let Total = (completedTask * 100) / totalTask;
              let FinalTotal = Math.round(Total);
              setCompletePercentage(FinalTotal);
              const iterations = FinalTotal / 10;
              setCompleteIteration(iterations);
              getCompletedTaskCount(catid,startADate)
              

              
            } else {
              setEmployeeTaskList([]);
              setAvaialableEmployee([]);
              setAvailableCnt(0)
              setCompletePercentage(0);
              const iterations = 0;
              setCompleteIteration(iterations);
              setShowLoader(false);
            }
            setUpload(!upload);
          }
        })
        .catch((err) => {
          setShowLoader(false);
        });
    } else {
      setEmployeeTaskList([]);
      setShowLoader(false);
    }
  };

  const ChangeDateFun = async (catid, searchdate, searchday) => {
    //if (!searchString) searchString = null;
    localStorage.setItem("categoryrsetid", catid);
    setDropDownSelect(catid);
    console.log("Selected Value", catid);

    //alert(UserId)
    if (catid > 0) {
      setShowLoader(true);
      let userids = localStorage.getItem("userId");
      await makeGetRequest(
        "employeeschedular/employeereporttask/" +
          userids +
          "/'" +
          searchdate +
          "'/" +
          catid +
          "/'" +
          searchday +
          "'" +
          "/" +
          sortColumnName +
          "/" +
          sortBy +
          "/" +
          null
      )
        .then((response) => {
          //console.log(response.data.data.rows);
          if (response.data.data.res.rows) {
            //console.log(response.data.data);
            //alert(response.data.data.rows[0].categories.length);
            if (response.data.data.res.rows.length > 0) {
              //console.log("Sagar", response.data.data.res.rows[0].assigntask[0].after_img)
              //console.log("Sagar",response.data.data.res.rows[0].assigntask[0].before_img)

              setEmployeeTaskList(response.data.data.res.rows);
              setCompleteCount(
                response.data.data.rescounts.rows[0].total_count
              );
              let totalTask = response.data.data.res.rowCount;
              let completedTask =
                response.data.data.rescounts.rows[0].total_count;
              let Total = (completedTask * 100) / totalTask;
              let FinalTotal = Math.round(Total);
              setCompletePercentage(FinalTotal);
              const iterations = FinalTotal / 10;
              setCompleteIteration(iterations);
              getCompletedTaskCount(catid,searchdate)
              //setShowLoader(false);
            } else {
              setEmployeeTaskList([]);
              setAvaialableEmployee([])
              setAvailableCnt(0)
              setCompletePercentage(0);
              const iterations = 0;
              setCompleteIteration(iterations);
              setShowLoader(false);
            }
            setUpload(!upload);
          }
        })
        .catch((err) => {
          setShowLoader(false);
        });
    } else {
      setEmployeeTaskList([]);
    }
  };

  async function getAllCategoryList(searchString) {
    if (!searchString) searchString = null;
    setShowLoader(true);
    await makeGetRequest(
      "category/getcategorylist/categoryid/asc/" + searchString
    )
      .then((response) => {
        if (response.data.data.res) {
          if (response.data.data.res.length > 0) {
            setCategoryList(response.data.data.res);

            setCategoryID(response.data.data.res[0].categoryid);
            setCategoryName(response.data.data.res[0].category_name);
            setFrequancyID(response.data.data.res[0].frequancy_ids);

            const datesArray = getDatesArray(startDate, endDate);
            setMondaysCal(datesArray);
            statusChangeFun(response.data.data.res[0].categoryid);
            //setShowLoader(false);
          } else {
            setCategoryList([]);
            setShowLoader(false);
          }
          setUpload(!upload);
        }
        
      })
      .catch((err) => {
        setShowLoader(false);
      });
  }

  React.useEffect(() => {
    if (categoryid == null || categoryid == "" || categoryid.length == 0) {
      getAllCategoryList(null);
      setUserId(localStorage.getItem("userId"));
      let id = localStorage.getItem("userId");
    }
  }, []);
  return (
    <>
      <div className="wrapper">
        {/* TopBar */}
        <div className="innerPageTopSecBackPatch"></div>
        <div className="innerPageTopSec">
          <div className="forBackArrow">
            <AppDrawer />
            {/* <NavigateBeforeIcon onClick={() => navigate("/")} /> */}
          </div>
          <h1>Reports</h1>
          <div className="forBackArrow"></div>
        </div>
        {/* TopBarEnd */}

        <div className="addAdminEmployeeMain reportAdminMain">
          <div className="tabsMainD">
            <Box sx={{ width: "100%" }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                {categoryList ? (
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    variant="scrollable"
                    scrollButtons="auto"
                  >
                    {categoryList.map((catObj, i) => (
                      <Tab
                        label={catObj.category_name}
                        onClick={(e) =>
                          changeList(e, catObj.categoryid, catObj.category_name)
                        }
                      />
                    ))}
                  </Tabs>
                ) : (
                  <></>
                )}
              </Box>
              {categoryList.map((catObj, i) => (
                <CustomTabPanel value={value} index={i}>
                  <div className="tabInrContDiv">
                    <div className="storOpeningMain">
                      <div className="mySlik clickAllTile">
                        <div className="slider-container">
                          {/* {JSON.stringify(mondaysCal)} */}
                          <Slider
                            className="dateSliderOuter"
                            {...settings}
                            ref={(slider) => setSlider1(slider)}
                          >
                            {mondaysCal.map((obs, idx) => (
                              <>
                                <div
                                  className="dateSliderInner"
                                  onClick={(e) => SelectSliders(idx, obs.date)}
                                >
                                  <span>{obs.day}</span>
                                  <h3>{obs.formatdate}</h3>
                                </div>
                              </>
                            ))}
                          </Slider>
                        </div>
                      </div>
                      <div className="listingSearch">
                        <input
                          type="text"
                          placeholder="Search"
                          onChange={(e) => getSerachList(e.target.value)}
                        />
                        <div className="sortingIcoOut">
                          <ToggleButton
                            value="check"
                            selected={selected}
                            onChange={() => {
                              handleChangeSortBy();
                            }}
                          >
                            <ImportExportIcon
                              className="visitorFilterIco"
                              aria-haspopup="true"
                            />
                          </ToggleButton>
                        </div>
                      </div>

                      {availablecnt > 0?<div class="repViw">
                       
                       {avaialableEmployee.map((availableobj, index) => (
                        <p>
                          <em></em>
                          <span>{availableobj.full_name} <b class="repViewCount">({availableobj.completed_tasks})</b></span>
                        </p>
                       ))}
                       
                        </div>:<></>}
                      
                      {completeCount > 0 ? (
                        <div className="progBarMain">
                          <div className="progBarInr">
                          <section>
                      {completePercentage > 0 ? (
                        <ol class="progress-bar">
                          {Array.from({ length: 10 }, (_, i) =>
                            completePercentage >= 10 * (i + 1) &&
                            completePercentage >= 10 * (i + 2) ? (
                              <li key={i} class="is-complete"></li>
                            ) : completePercentage >= 10 * (i + 1) &&
                              completePercentage <= 10 * (i + 2) ? (
                              <li key={i} class="is-active">
                                {completePercentage}%
                              </li>
                            ) : (
                              <li key={i}></li>
                            )
                          )}
                        </ol>
                      ) : null}</section>
                          </div>
                        </div>
                      ) : null}

                      {employeeTaskList.length > 0 ? (
                        <div
                          className="employyeListingMain"
                          style={{ marginTop: 10 }}
                        >
                          {employeeTaskList.map((takObj, index) => (
                            <div className={`swipe-container`}>
                              <div
                                className="emploTaskListInr"
                                id="listID"
                                onClick={forSelectList}
                              >
                                <div className="withCheck">
                                  <div
                                    className={
                                      takObj.assigntask != null
                                        ? takObj.assigntask[0].taskstatus ==
                                          "Assigned"
                                          ? "class2"
                                          : "class1"
                                        : ""
                                    }
                                  >
                                    <img src={unlock} className="forClass1" />
                                    <VerifiedIcon className="forClass2" />
                                    <LockPersonIcon className="forClass3" />
                                  </div>
                                  <div>
                                    <h1>{takObj.task_name}</h1>
                                    <span className="inrTileTaskName">
                                      {categoryname}
                                    </span>
                                    {takObj.assigntask != null ? (
                                      <em className="byWicTx">
                                        {takObj.assigntask != null
                                          ? takObj.assigntask[0].full_name
                                          : null}
                                        ,{" "}
                                        {moment(
                                  takObj.assigntask[0].created_at
                                ).format("LTS")}
                                      </em>
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                </div>

                                {takObj.is_beforeafter ? (
                                  <div className="taskImUpload">
                                    <FilterIcon
                                      onClick={() => handleClickOpen(takObj)}
                                    />
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <>
                          <div className="dataNoF">
                            <img src={noPic} />
                            <p>Data Not Found</p>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </CustomTabPanel>
              ))}
            </Box>
          </div>
        </div>

        <AdminBottomNav />

        <FloatButton route1={"EmployeeTaskList"} />
      </div>

      {/* BeforAfterImage */}
      <div className="">
        <React.Fragment>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="myAppModalMain forBeforAfterMod"
          >
            <DialogTitle id="alert-dialog-title">
              <h1 className="modalHeading">Before & After Pictures</h1>
              <IconButton className="modalCloseIco" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <div className="modalBodyCont">
                  <div className="beforAftMain">
                    <div className="beforSt">
                      <h1 className="befAftHeading">Before</h1>
                      {ImageUploadBefore ? (
                        <>
                          {ImageUploadBefore.map((img) => (
                            <div className="befAftImgDiv">
                              <img src={img} />
                            </div>
                          ))}
                        </>
                      ) : (
                        <p>No Image Found</p>
                      )}
                    </div>
                    <div className="afterSt">
                      <h1 className="befAftHeading">After</h1>
                      {ImageUploadAfter ? (
                        <>
                          {ImageUploadAfter.map((img) => (
                            <div className="befAftImgDiv">
                              <img src={img} />
                            </div>
                          ))}
                        </>
                      ) : (
                        <p>No Image Found</p>
                      )}
                    </div>
                  </div>
                </div>
              </DialogContentText>
            </DialogContent>
          </Dialog>
        </React.Fragment>
      </div>

      {showLoader ? (
        <div className="loaderNew">
          <div className="loader"></div>
        </div>
      ) : (
        <></>
      )}
    </>
  );

  // function NextArrow(props) {
  //   const datesArrowArrayVal = getArrowDatesArray(startDate, endDate);
  //   const selectedDate = datesArrowArrayVal[props.currentSlide];
  //   console.log("Selected date:", selectedDate);
  //   if (startADate != selectedDate) {
  //     setStartADate(selectedDate);
  //     const date = moment(selectedDate);
  //     const dayName = date.format("dddd");
  //     setWeekDay(dayName);
  //     ChangeDateFun(categoryid, selectedDate, dayName);
  //   }
  //   return (
  //     <button class="slick-arrow slick-next" onClick={props.onClick}>
  //       {" "}
  //       Next
  //     </button>
  //   );

  //   return false;
  // }
}

export default ReportAdmin;

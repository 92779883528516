import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Grid, Box } from "@mui/material";
import { makePostRequest, makePostLoginRequest } from "../../../utils/utils";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../../FormikControl";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import moment from "moment/moment";
import swal from "sweetalert";

const AdminLogin = ({ length = 4 }) => {
  const navigate = useNavigate();

  const [loginErrMsg, setLoginErrMsg] = React.useState("");
  const [showLoader, setShowLoader] = React.useState(false);
  const [todaysDate, setTodaysDate] = useState({ time: new Date() });
  // For PIN
  const [upload, setUpload] = React.useState(false);
  const [otp, setOtp] = useState(Array(length).fill(""));
  const inputsRef = useRef([]);

  const handleChange = (e, index) => {
    const { value } = e.target;

    // Only allow numeric input and limit to one character
    if (/^\d$/.test(value)) {
      const newOtp = [...otp];
      //newValue.substring(newValue.length - 1, newValue.length);
      newOtp[index] = value;
      setOtp(newOtp);

      // Move to next field if it's not the last one
      // alert(index)
      // alert(length)
      if (index < length - 1) {
        inputsRef.current[index + 1].focus();
      }

      if (
        newOtp.length == 4 &&
        newOtp[0] != "" &&
        newOtp[1] != "" &&
        newOtp[2] != "" &&
        newOtp[3] != ""
      ) {
        let values = {};
        localStorage.removeItem("categorysetid");
        let temp = newOtp.toString();
        let newpassword = temp.replace(/,/g, "");
        values.email_id = "sagar@gmail.com";
        //values.email_id = "abhayb.supra@gmail.com";
        //values.email_id = "vanshb.supra@gmail.com";
        values.password = newpassword;
        console.log("Form Data", newpassword);

        setShowLoader(true);
        setLoginErrMsg("");
        makePostLoginRequest("superadmin/superadminlogin", values)
        .then((response) => {
          if (response.data.data) {
            console.log(response);
            if(response.data.data.status !== false) {
              
            if (response.data.data.user_type == "User") {
              //alert("USer")
              sessionStorage.setItem("accessToken", response.data.data.token_id);
              localStorage.setItem("userType", response.data.data.user_type);
              localStorage.setItem("userId", response.data.data.employee_id);
              localStorage.setItem("companyId", response.data.data.company_id);
              localStorage.setItem("adminProfilePic",response.data.data.profile_pic);
              localStorage.setItem("UserName", response.data.data.full_name);
              localStorage.setItem("employee_code", response.data.data.employee_code);
              sessionStorage.setItem("schema", response.data.data.schemaname);
              localStorage.setItem("companyLogo", response.data.data.company_logo);
              
              //sessionStorage.setItem("schema", 'public');
              
              navigate('/EmployeeTaskList');
            } else {
              console.log(response.data.data);
              
              sessionStorage.setItem("accessToken", response.data.data.token_id);
              localStorage.setItem("userType", response.data.data.user_type);
              localStorage.setItem("userId", response.data.data.employee_id);
              localStorage.setItem("companyId", response.data.data.company_id);
              localStorage.setItem("adminProfilePic", response.data.data.profile_pic);
              localStorage.setItem("UserName", response.data.data.full_name);
              if(response.data.data.employee_code >0 && response.data.data.employee_code!='')
                localStorage.setItem("employee_code", response.data.data.employee_code);
              else
              localStorage.setItem("employee_code", '-');
              sessionStorage.setItem("schema", response.data.data.schemaname);
              //sessionStorage.setItem("schema", 'public');
              setUpload(!upload);
              if (response.data.data.user_type == "Admin") {
                //alert("Admin")
                sessionStorage.setItem("accessToken", response.data.data.token_id);
                localStorage.setItem("userType", response.data.data.user_type);
                localStorage.setItem("userId", response.data.data.employee_id);
                localStorage.setItem(
                  "companyId",
                  response.data.data.company_id
                );
                localStorage.setItem(
                  "adminProfilePic",
                  response.data.data.profile_pic
                );
                localStorage.setItem("UserName", response.data.data.full_name);
                localStorage.setItem(
                  "employee_code",
                  response.data.data.employee_code
                );
                localStorage.setItem("companyLogo", response.data.data.company_logo);
                sessionStorage.setItem("schema", response.data.data.schemaname);
                setShowLoader(false);
                navigate("/ReportAdmin");
              } else {
                //sessionStorage.setItem("schema", response.data.data.schemaname);
                sessionStorage.setItem("schema", 'public');
                sessionStorage.setItem("companyName",response.data.data.company_name);
                setShowLoader(false);
                navigate('/SuperAdminDashboard');
              }
              //setShowLoader(false);
            }
              //setShowLoader(false);
            } else {
              swal("Your account is Inactive", "Information", "warning", {
                timer: 3000,
                buttons: false,
              });
              setOtp(Array(length).fill(""));
              inputsRef.current[0].focus();
              setShowLoader(false);
            }
          } else {
            setShowLoader(false);
          }
          
        })
        .catch((err) => {
          setOtp(Array(length).fill(""));
          inputsRef.current[0].focus();
          setLoginErrMsg("Please Enter Valid Pin");
          setShowLoader(false);
        });  
      }
    }
  };

  const handleKeyDown = (key, index) => {
    if (key.key === "Backspace" || key.key === "Delete") {
      /* const newOtp = [...otp];
        newOtp[index] = "";
        if (index > 0) {
          inputsRef.current[index - 1].focus();
        }
        //setCurrentOtpIndex(curr_index);
        setOtp(newOtp); */
      setOtp(Array(length).fill(""));
      inputsRef.current[0].focus();
    } else {
      if (key.target.value != "") {
        if (index < length - 1) {
          inputsRef.current[index + 1].focus();
        }
      }
    }
  };
  const onSubmit = () => {
    
  }

  useEffect(() => {
    // Set focus on the first input field when the component mounts
    inputsRef.current[0]?.focus();
    setInterval(() => setTodaysDate({ time: new Date() }), 1000);
  }, []);
  // End

  const initialValues = {
    password: "",
  };

  

  return (
    <div className="adminLoginMain keyboardStyle">
      <div className="adminLoginTop">
        <h1>ToDoBoom</h1>
      </div>
      <div className="adminLoginBody">
        <Formik initialValues={initialValues} onSubmit={onSubmit}>
          {(formik) => (
            <Form>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                  <h1 className="loginDay">
                    {moment(todaysDate.time).format("dddd")}
                  </h1>
                  <h2 className="loginDatTime">
                    {moment(todaysDate.time).format("L")} ,{" "}
                    <span>{moment(todaysDate.time).format("LTS")}</span>
                  </h2>
                </Grid>
                <Grid item xs={12} md={12}>
                  <label className="myLable">Enter Your Pin</label>
                  <div className="myOtpMain">
                    {otp.map((value, index) => (
                      <input
                        placeholder="-"
                        key={index}
                        type="text"
                        value={value}
                        onChange={(e) => handleChange(e, index)}
                        onKeyDown={(e) => handleKeyDown(e, index)}
                        maxLength={1}
                        ref={(el) => (inputsRef.current[index] = el)}
                        name="password"
                        style={{
                          width: "40px",
                          height: "40px",
                          textAlign: "center",
                          fontSize: "18px",
                          border: "1px solid #ccc",
                          borderRadius: "4px",
                        }}
                      />
                    ))}
                  </div>
                </Grid>
                <div style={{ marginLeft: 25 }}>
                  <p className="errorSt">{loginErrMsg}</p>
                </div>
                <Grid item xs={12} md={12}>
                  {/* <Button type="submit" className="logginBt">Login</Button> */}
                  {/*<Button
                    className="logginBt"
                    onClick={() => navigate("/Login")}
                  >
                    OK
                  </Button>*/}
                </Grid>
                {/* <Grid item xs={12} md={12}>
                  <p className="doNotHave">
                    Do not have an account? <span>Register</span>
                  </p>
                </Grid> */}
              </Grid>
            </Form>
          )}
        </Formik>
      </div>
      {showLoader ? (
        <div className="loaderNew">
          <div className="loader"></div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default AdminLogin;

import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router-dom";
import avtarPic from "../assets/images/avtarPic.png";
import PersonIcon from "@mui/icons-material/Person";
import LogoutIcon from "@mui/icons-material/Logout";
import compLog from "../assets/images/demoL.png";

function AppDrawer() {
  const navigate = useNavigate();
  const [showLoader, setShowLoader] = React.useState(false);
  const [UserName, setUserName] = useState();
  const [profilepic, setprofilepic] = useState();
  const [companypic, setCompanyPic] = useState();
  const [userId, setUserId] = useState();
  const [userType, setUserType] = useState();
  const [companyName, setcompanyName] = useState();

  const getAdminDetails = () => {
    setShowLoader(true);
    setUserName(localStorage.getItem("UserName"));
    setprofilepic(localStorage.getItem("adminProfilePic"));
    setUserId(localStorage.getItem("userId"));
    setUserType(localStorage.getItem("userType"));
    setcompanyName(sessionStorage.getItem("companyName"));
    setCompanyPic(localStorage.getItem("companyLogo"))
    setShowLoader(false);
  };
  const logoutFunction = () => {
    sessionStorage.clear();
    localStorage.clear();
    navigate("/");
  };
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const DrawerInputs = [
    {
      name: "User",
      icon: <PersonIcon />,
      route: "/EmployeeTaskList",
    },
  ];

  const DrawerList = (
    <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)}>
      <List>
        {DrawerInputs.map((drawObj, index) => (
          <ListItem key={drawObj.name} disablePadding>
            <ListItemButton onClick={() => navigate(`${drawObj.route}`)}>
              <ListItemIcon>{drawObj.icon}</ListItemIcon>
              <ListItemText primary={drawObj.name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const adminDetailsView = (
    <div className="drawerAdminView">
      <div className="avtardiv">
        {profilepic ? <img src={profilepic} /> : <img src={avtarPic} />}
        <p>{UserName}</p>
        <span className="userTypeName">{userType}</span>
        <span className="userTypeName">{companyName}</span>
        <span className="userTypeName">
          Employee Code: {localStorage.getItem("employee_code")}
        </span>
      </div>
    </div>
  );

  useEffect(() => {
    getAdminDetails();
    //console.log("useName", UserName, "userId", userId, "userType", userType, "Comapny", companyName)
  }, []);

  return (
    <div>
      <div className="comnCompaniesLogoMain">
        <MenuIcon onClick={toggleDrawer(true)} />
        <div className="alCompLogs">
          {companypic ? <img src={companypic} /> : <img src={avtarPic} />}
        </div>
      </div>
      <Drawer open={open} onClose={toggleDrawer(false)}>
        {adminDetailsView}
        {DrawerList}
        <div className="logoutView">
          <div className="logoutBtn" onClick={logoutFunction}>
            <p>Logout</p>
            <LogoutIcon />
          </div>
        </div>
      </Drawer>
      {showLoader ? (
        <div className="loaderNew">
          <div className="loader"></div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export default AppDrawer;

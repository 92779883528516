import React from "react";
import { Field, ErrorMessage } from "formik";
import TextError from "./TextError";

function RadioButton(props) {
  const { label, name, options, labelClass, ...rest } = props;
  return (
    <div className="form-control">
      <label className={labelClass}>{label}</label>
      <div className="valRadioOut">
        <Field name={name} {...rest}>
          {({ field }) => {
            return options.map((option) => {
              return (
                <React.Fragment key={option.key}>
                  <div className="valRadioInr">
                    <input
                      type="radio"
                      id={option.value}
                      {...field}
                      value={option.value}
                      checked={field.value === option.value}
                    />
                    <label htmlFor={option.value}>{option.key}</label>
                  </div>
                </React.Fragment>
              );
            });
          }}
        </Field>
      </div>
      <ErrorMessage name={name} component={TextError} />
    </div>
  );
}

export default RadioButton;

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, Box, Button } from "@mui/material";
import "react-medium-image-zoom/dist/styles.css";
import AdminBottomNav from "../AdminBottomNav/AdminBottomNav";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import "react-medium-image-zoom/dist/styles.css";
import EastIcon from "@mui/icons-material/East";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import AddIcon from "@mui/icons-material/Add";
import Slider from "react-slick";
import IconButton from "@mui/material/IconButton";

import { TimeRangePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";

import CloseIcon from "@mui/icons-material/Close";

// For Accordian
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// End

import edit from "../../../assets/images/edit2.svg";
import deleteIm from "../../../assets/images/Delete.svg";
import users from "../../../assets/images/Users.svg";

// For Modal
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
// End

function CreateNewSchedule() {
  const navigate = useNavigate();

  let [count, setCount] = useState(0);

  function incrementCount() {
    count = count + 1;
    setCount(count);
  }
  function decrementCount() {
    count = count - 1;
    setCount(count);
  }

  // For Accordian
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  // End

  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "0px",
    slidesToShow: 5,
    speed: 500,
  };

  return (
    <>
      <div className="wrapper">
        <div className="forUpperBottomBt">
          {/* TopBar */}
          <div className="innerPageTopSecBackPatch"></div>
          <div className="innerPageTopSec">
            <div className="forBackArrow">
              <NavigateBeforeIcon onClick={() => navigate("/AdminTasksList")} />
            </div>
            <h1>Create New Weekly Schedule</h1>
            <div className="forBackArrow"></div>
          </div>
          {/* TopBarEnd */}

          <div className="addAdminEmployeeMain">
            <Grid container spacing={3}>
              <Grid item xs={12} md={12}>
                <p className="hrsOperation">
                  Hours of Operation <b>09:00am</b>
                  <ArrowForwardIcon />
                  <b>07:00pm</b>
                </p>
              </Grid>
              <Grid item xs={12} md={12}>
                <label className="myLable">Enter Shift Schedule Name</label>
                <input
                  className="myInput"
                  placeholder="Enter Shift Schedule Name"
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <div className="schedulAcordianTpRight">
                  <FormControlLabel
                    control={<Switch defaultChecked />}
                    label="Apply this as default template"
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={12}>
                <div className="mySlik createScheduSlik">
                  <div className="slider-container">
                    <Slider {...settings}>
                      <div>
                        <h3>Mon</h3>
                      </div>
                      <div>
                        <h3>Tue</h3>
                      </div>
                      <div>
                        <h3>Wed</h3>
                      </div>
                      <div>
                        <h3>Thu</h3>
                      </div>
                      <div>
                        <h3>Fri</h3>
                      </div>
                      <div>
                        <h3>Sat</h3>
                      </div>
                      <div>
                        <h3>Sun</h3>
                      </div>
                    </Slider>
                  </div>
                </div>
              </Grid>

              <Grid item xs={12} md={12}>
                <div className="schedularAccordianMain">
                  <Accordion
                    expanded={expanded === "panel1"}
                    onChange={handleChange("panel1")}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      <Typography sx={{ width: "33%", flexShrink: 0 }}>
                        Kitchen <span>2 Shifts</span>
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <div className="afterAddShiftDiv">
                          <div className="afterAddShiftInr">
                            <em>Shifts</em>
                            <p>09:30am - 12:30pm</p>
                            <span>3hrs</span>
                            <div className="actionAftrSc">
                              <IconButton aria-label="edit" className="usrsB">
                                <img src={users} /> 3
                              </IconButton>
                              <IconButton aria-label="edit">
                                <img src={edit} />
                              </IconButton>
                              <IconButton aria-label="delete">
                                <img src={deleteIm} />
                              </IconButton>
                            </div>
                          </div>

                          <div className="afterAddShiftInr">
                            <em>Shifts</em>
                            <p>12:30pm - 04:30pm</p>
                            <span>4hrs</span>
                            <div className="actionAftrSc">
                              <IconButton aria-label="edit" className="usrsB">
                                <img src={users} /> 2
                              </IconButton>
                              <IconButton aria-label="edit">
                                <img src={edit} />
                              </IconButton>
                              <IconButton aria-label="delete">
                                <img src={deleteIm} />
                              </IconButton>
                            </div>
                          </div>
                        </div>
                        <label className="schedLab">Add New Shift</label>
                        <div className="scheduCon">
                          <div>
                            <div className="timingAddDiv">
                              <div className="timingAddLeft">
                                <label>Shift Hours</label>
                                <TimeRangePicker
                                  format="hh:mm aa"
                                  showMeridiem
                                />
                              </div>
                              <div className="timingAddLeft">
                                <label>Emp Per Shift</label>
                                <div className="countD">
                                  <button onClick={decrementCount}>-</button>
                                  <div>{count}</div>
                                  <button onClick={incrementCount}>+</button>
                                </div>
                              </div>
                            </div>
                            <div className="setDayMain">
                              <div className="dayInD">All</div>
                              <div className="dayInD activDs">M</div>
                              <div className="dayInD">T</div>
                              <div className="dayInD activDs">W</div>
                              <div className="dayInD">T</div>
                              <div className="dayInD">F</div>
                              <div className="dayInD">S</div>
                              <div className="dayInD">S</div>
                            </div>
                            <div className="twoBtLi">
                              <Button className="addMorScheTimBt saveShiftB">
                                <AddIcon /> Save Shift
                              </Button>
                              <Button className="addMorScheTimBt">
                                <AddIcon /> Add More Shift
                              </Button>
                            </div>
                          </div>
                        </div>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    expanded={expanded === "panel2"}
                    onChange={handleChange("panel2")}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2bh-content"
                      id="panel2bh-header"
                    >
                      <Typography sx={{ width: "33%", flexShrink: 0 }}>
                        Dining <span>No Shifts</span>
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        {/* <div className="afterAddShiftDiv">
                          <div className="afterAddShiftInr">
                            <em>Shifts</em>
                            <p>09:30am - 12:30pm</p>
                            <span>3hrs</span>
                            <div className="actionAftrSc">
                              <IconButton aria-label="edit" className="usrsB">
                                <img src={users} /> 3
                              </IconButton>
                              <IconButton aria-label="edit">
                                <img src={edit} />
                              </IconButton>
                              <IconButton aria-label="delete">
                                <img src={deleteIm} />
                              </IconButton>
                            </div>
                          </div>

                          <div className="afterAddShiftInr">
                            <em>Shifts</em>
                            <p>09:30am - 12:30pm</p>
                            <span>3hrs</span>
                            <div className="actionAftrSc">
                              <IconButton aria-label="edit" className="usrsB">
                                <img src={users} /> 3
                              </IconButton>
                              <IconButton aria-label="edit">
                                <img src={edit} />
                              </IconButton>
                              <IconButton aria-label="delete">
                                <img src={deleteIm} />
                              </IconButton>
                            </div>
                          </div>
                        </div> */}
                        <label className="schedLab">Add New Shift</label>
                        <div className="scheduCon">
                          <div>
                            <div className="timingAddDiv">
                              <div className="timingAddLeft">
                                <label>Shift Hours</label>
                                <TimeRangePicker
                                  format="hh:mm aa"
                                  showMeridiem
                                />
                              </div>
                              <div className="timingAddLeft">
                                <label>Emp Per Shift</label>
                                <div className="countD">
                                  <button onClick={decrementCount}>-</button>
                                  <div>{count}</div>
                                  <button onClick={incrementCount}>+</button>
                                </div>
                              </div>
                            </div>
                            <div className="setDayMain">
                              <div className="dayInD">All</div>
                              <div className="dayInD activDs">M</div>
                              <div className="dayInD">T</div>
                              <div className="dayInD activDs">W</div>
                              <div className="dayInD">T</div>
                              <div className="dayInD">F</div>
                              <div className="dayInD">S</div>
                              <div className="dayInD">S</div>
                            </div>
                            <div className="twoBtLi">
                              <Button className="addMorScheTimBt saveShiftB">
                                <AddIcon /> Save Shift
                              </Button>
                              <Button className="addMorScheTimBt">
                                <AddIcon /> Add More Shift
                              </Button>
                            </div>
                          </div>
                        </div>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </div>

                <div className="withPlsBt">
                  <label className="myLable">Add New Locations</label>
                  <div className="withPlsBtInr">
                    <input
                      className="myInput"
                      placeholder="Enter Location Name"
                    />
                    <Button className="plsB">
                      <AddIcon />
                    </Button>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>

          <AdminBottomNav />
        </div>
      </div>
    </>
  );
}

export default CreateNewSchedule;
